import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
    PaginateData,
    PaginateOption,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Pattern } from '../models/pattern.model';

@Injectable()
export class PatternJourneyRepository extends Repository
  implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient
            .get('/journeypattern/getbytype', option)
            .pipe(
                map((res: any) => {
                    const patterns = [];
                    if (res.records.patterns.patterns) {
                        for (const item of res.records.patterns.patterns) {
                            patterns.push(this.parseResponse(item));
                        }
                    }
                    return {
                        data: patterns,
                        page: res.records.patterns.currentPage || 0,
                        total: res.records.patterns.totalRecord,
                        pageSize: option.displayItem,
                    };
                })
            );
    };
  getPatternByDirection(params: any): Observable<Pattern[]> {
      return this.httpClient.get('/journeypattern/getbypattern', params).pipe(
          map((response: any) => {
              const patterns = [];
              if (response.records.patterns.patterns) {
                  for (const item of response.records.patterns.patterns) {
                      patterns.push(this.parseResponse(item));
                  }
              }
              return patterns;
          })
      );
  }
    getPatternByWorkDate(WorkDate: any): Observable<Pattern[]> {
        return this.httpClient.get('/journeypattern/getallbyworkdate?WorkDate=' + WorkDate).pipe(
            map((response: any) => {
                const patterns = [];
                if (response.records.patterns) {
                    for (const item of response.records.patterns) {
                        patterns.push(this.parseResponse(item));
                    }
                }
                return patterns;
            })
        );
    }
  getAll(): Observable<Pattern[]> {
    return this.httpClient.get('/journeypattern/getall').pipe(
      map((response: any) => {
        const patterns = [];
        let res = response.records;
        if (res.patterns) {
          for (const item of res.patterns) {
            patterns.push(this.parseResponse(item));
          }
        }
        return patterns;
      })
    );
  }

  getAllPattern(): Observable<Pattern[]> {
    return this.httpClient.get('/journeypattern/getallpattern').pipe(
      map((response: any) => {
        const patterns = [];
        let res = response.records;
        if (res.patterns) {
          for (const item of res.patterns) {
            patterns.push(this.parseResponse(item));
          }
        }
        return patterns;
      })
    );
  }



  duplicatePattern(id: number): Observable<Number> {
    return this.httpClient
      .get('/journeypattern/duplicate/' + id).pipe(
        map((res: any) => {
          return res.records.journeyPattern;
        })
      );
  }

  getInfo(id: Number): Observable<Pattern> {
    return this.httpClient.get('/journeypattern/detail/' + id).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.JourneyPattern);
      })
    );
  }

  updatePattern(pattern: any): Observable<any> {
    return this.httpClient.put('/journeypattern/update', pattern);
  }

  createPattern(pattern: any): Observable<any> {
    return this.httpClient.post('/journeypattern/create', pattern);
  }

  deletePattern(id: number): Observable<any> {
    return this.httpClient.delete('/journeypattern/delete/' + id);
  }

  parseResponse(data: any): Pattern {
    const pattern = new Pattern();
    pattern.fill(data);
    return pattern;
  }
}
