<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
          <section id="journey-calendar">
            <m-card [options]="options" (reloadFunction)="reloadEvents($event)">
              <ng-container mCardBody>
                <div class="row">
                  <div class="col-10 mb-3">
                    <button class="btn btn-raised btn-primary pull-right" (click)="addJourney()">
                      {{ 'journey.journey_add_to_calendar' | translate }}
                    </button>
                  </div>
                  <div class="col-2 mb-2">
                    <button type="button" class="btn btn-info pull-right" (click)="exportJourney()">
                      <i class="la la-download"></i> {{ 'journey.export_journey_calendar' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="btn-group">
                      <div
                        class="btn btn-primary"
                        mwlCalendarPreviousView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()"
                      >
                        {{ 'journey.previous' | translate}}
                      </div>
                      <div
                        class="btn btn-outline-secondary"
                        mwlCalendarToday
                        [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()"
                      >
                        {{ 'journey.today' | translate}}
                      </div>
                      <div
                        class="btn btn-primary"
                        mwlCalendarNextView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()"
                      >
                        {{ 'journey.next' | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-center">
                    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
                  </div>
                  <div class="col-md-4 text-right">
                    <div class="btn-group">
                      <div
                        (click)="setView(CalendarView.Month)"
                        [class]="(view === CalendarView.Month) ? 'btn btn btn-primary active' : 'btn btn-primary'"
                      >
                        {{ 'journey.month' | translate}}
                      </div>
                      <div
                        (click)="setView(CalendarView.Week)"
                        [class]="(view === CalendarView.Week) ? 'btn btn btn-primary active' : 'btn btn-primary'"
                      >
                        {{ 'journey.week' | translate}}
                      </div>
                      <div
                        (click)="setView(CalendarView.Day)"
                        [class]="(view === CalendarView.Day) ? 'btn btn btn-primary active' : 'btn btn-primary'"
                      >
                        {{ 'journey.day' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <ng-template #monthCellTemplate let-day="day" let-locale="locale">
                  <div class="cal-cell-top">
                    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                  </div>
                  <div class="cell-totals">
                    <ul *ngFor="let group of day.eventGroups; let i = index">
                      <li *ngIf="group[0] === 'departure'" class="cell-departure">
                        <div class="badge-warning calendar-tool">
                          <div class="total-departure">
                            <img src="/assets/images/ico/ico_departure.svg" alt="departure"> {{ 'journey_calendar.added' | translate }}: {{group[1][0].meta.journeys.length}} / {{ 'journey_calendar.total' | translate }}: {{group[1][0].meta.numberJourneyPattern}}
                          </div>
                          <div class="tools">
                            <a *ngIf="group[0] === 'departure' && group[1][0].meta.journeys.length != group[1][0].meta.numberJourneyPattern && isToday(group[1][0].meta.workDate)" id="refresh_journey_departure" title="refresh list journey calendar" (click)="refreshJourneyCalendar(group[1][0].meta.id, group[1][0].meta.workDate, group[1][0].meta.patternName)"><img
                                    src="/assets/images/ico/refresh_departure_ico.svg" alt=""></a>
                            <a *ngIf="group[0] === 'departure' && isToday(group[1][0].meta.workDate)" (click)="deleteAllJourney(group[1][0].meta)" title="delete pattern"><img
                                    src="/assets/images/ico/close_departure_ico.svg" alt="close"></a>
                          </div>
                        </div>
                        <div *ngIf="group[0] === 'departure'" class="pattern" (click)="handleEvent('show', group[1][0])">
                        {{group[1][0].meta.patternName}}
                        </div>
                      </li>
                      <li *ngIf="group[0] === 'arrival' " class="cell-arrival">
                        <div class="badge-info calendar-tool">
                          <div class="total-arrival">
                            <img src="/assets/images/ico/ico_arrival.svg" alt="departure"> {{ 'journey_calendar.added' | translate }}: {{group[1][0].meta.journeys.length}} / {{ 'journey_calendar.total' | translate }}: {{group[1][0].meta.numberJourneyPattern}}
                          </div>
                          <div class="tools">
                            <a *ngIf="group[0] === 'arrival' && group[1][0].meta.journeys.length != group[1][0].meta.numberJourneyPattern && isToday(group[1][0].meta.workDate)" title="refresh list journey calendar" id="refresh_journey_arrival" (click)="refreshJourneyCalendar(group[1][0].meta.id, group[1][0].meta.workDate, group[1][0].meta.patternName)"><img
                                    src="/assets/images/ico/refresh_arrival_ico.svg" alt="arrival"></a>
                            <a *ngIf="group[0] === 'arrival' && isToday(group[1][0].meta.workDate)" (click)="deleteAllJourney(group[1][0].meta)" title="delete pattern"><img
                                    src="/assets/images/ico/close_arrival_ico.svg" alt="close"></a>
                          </div>
                        </div>
                        <div *ngIf="group[0] === 'arrival' " class="pattern" (click)="handleEvent('show', group[1][0])">
                        {{group[1][0].meta.patternName}}
                        </div>
                      </li>
                    </ul>
                  </div>
                </ng-template>
                <ng-template #loading>
                  <div class="text-center">
                    <i class="la la-spin la-spinner"></i> <br />
                    {{ 'journey.loading' | translate }}
                  </div> 
                </ng-template>
                <div *ngIf="journeyCalendar$ | async; else loading; let journeyCalendar">
                  <div [ngSwitch]="view">
                    <mwl-calendar-month-view
                      *ngSwitchCase="CalendarView.Month"
                      [viewDate]="viewDate"
                      [events]="journeyCalendar"
                      [refresh]="refresh"
                      [cellTemplate]="monthCellTemplate"
                      (beforeViewRender)="beforeMonthViewRender($event)"
                      [activeDayIsOpen]="activeDayIsOpen"
                      (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)"
                      [locale]="locale"
                    >
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view
                      *ngSwitchCase="CalendarView.Week"
                      [viewDate]="viewDate"
                      [events]="journeyCalendar"
                      (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)"
                      [locale]="locale"
                    >
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view
                      *ngSwitchCase="CalendarView.Day"
                      [viewDate]="viewDate"
                      [events]="journeyCalendar"
                      (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)"
                      [locale]="locale"
                    >
                    </mwl-calendar-day-view>
                  </div>
                </div>
              </ng-container>
            </m-card>
          </section>
        </div>
    </div>
</div>
