<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section id="horizontal-form-layouts">
                <div class="row">
                    <div class="col-md-12">
                        <m-card>
                            <ng-container mCardHeaderTitle>
                                {{'page.edit_profile' | translate}}
                            </ng-container>
                            <ng-container mCardBody>
                                <form class="form form-horizontal" [formGroup]="changePasswordForm"
                                      (ngSubmit)="onSubmit()">
                                    <div class="row form-group">
                                        <div class="col-3 text-right">
                                            <label for="oldPassword">{{'profile.old_password' | translate}}</label>
                                        </div>
                                        <div class="col-6">
                                            <div class="row input-group">
                                                <input class="form-control"
                                                       [type]="toggleOldPassword ? 'text' : 'password'"
                                                       placeholder="{{'profile.old_password' | translate}}"
                                                       id="oldPassword"
                                                       formControlName="oldPassword"
                                                       [ngClass]="{ 'is-invalid': submitted && changePasswordForm.controls.oldPassword.errors }"
                                                       name="oldPassword">
                                                <div (click)="toggleOldPasswordText()" style="cursor: pointer"  class="input-group-append">
                                                    <span class="input-group-text form-control" [ngClass]="
                                                    { 'focusInput': (focusInPassword && !submitted) || (focusInPassword && submitted
                                                    && !changePasswordForm.controls.oldPassword.errors), 'is-invalid': submitted
                                                    && changePasswordForm.controls.oldPassword.errors}">
                                                        <i class="la" [ngClass]="{
                                                            'la-eye-slash': toggleOldPassword,
                                                            'la-eye': !toggleOldPassword
                                                          }"></i>
                                                    </span>
                                                </div>
                                                <app-validation-error [field]="'profile.old_password'"
                                                                      [submitted]="submitted"
                                                                      [control]="changePasswordForm.controls.oldPassword">
                                                </app-validation-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-3 text-right">
                                            <label for="password">{{'profile.new_password' | translate}}</label>
                                        </div>
                                        <div class="col-6">
                                            <div class="row input-group">
                                                <input class="form-control"
                                                       [type]="togglePassword ? 'text' : 'password'"
                                                       placeholder="{{'profile.new_password' | translate}}"
                                                       id="password"
                                                       name="password"
                                                       [ngClass]="{ 'is-invalid': submitted && changePasswordForm.controls.password.errors }"
                                                       formControlName="password"
                                                >
                                                <div (click)="togglePasswordText()" style="cursor: pointer"  class="input-group-append">
                                                    <span class="input-group-text form-control" [ngClass]="{ 'focusInput': (focusInPassword && !submitted) || (focusInPassword && submitted && !changePasswordForm.controls.password.errors), 'is-invalid': submitted && changePasswordForm.controls.password.errors}">
                                                        <i class="la" [ngClass]="{
                                                            'la-eye-slash': togglePassword,
                                                            'la-eye': !togglePassword
                                                          }"></i>
                                                    </span>
                                                </div>
                                                <app-validation-error [field]="'profile.new_password'"
                                                                      [submitted]="submitted"
                                                                      [control]="changePasswordForm.controls.password">
                                                </app-validation-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-3 text-right">
                                            <label for="confirmPassword">{{'profile.re_new_password' | translate}}</label>
                                        </div>
                                        <div class="col-6">
                                            <div class="row input-group">
                                                <input class="form-control"
                                                       [type]="toggleConfirmPassword ? 'text' : 'password'"
                                                       placeholder="{{'profile.re_new_password' | translate}}"
                                                       id="confirmPassword"
                                                       name="confirmPassword"
                                                       [ngClass]="{ 'is-invalid': submitted && changePasswordForm.controls.confirmPassword.errors }"
                                                       formControlName="confirmPassword"
                                                >
                                                <div (click)="toggleConfirmPasswordText()" style="cursor: pointer"  class="input-group-append">
                                                    <span class="input-group-text form-control" [ngClass]="{ 'focusInput': (focusInPassword && !submitted) ||
                                                    (focusInPassword && submitted && !changePasswordForm.controls.confirmPassword.errors),
                                                    'is-invalid': submitted && changePasswordForm.controls.confirmPassword.errors}">
                                                        <i class="la" [ngClass]="{
                                                            'la-eye-slash': toggleConfirmPassword,
                                                            'la-eye': !toggleConfirmPassword
                                                          }"></i>
                                                    </span>
                                                </div>
                                                <app-validation-error [field]="'profile.re_new_password'"
                                                                      [submitted]="submitted"
                                                                      [control]="changePasswordForm.controls.confirmPassword">
                                                </app-validation-error>
                                            </div>
                                            <div class="row">
                                                <p class="description_password">{{"message.description_password1" | translate}}</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-actions text-center">
                                        <button type="button" class="btn btn-warning mr-1"
                                                [routerLink]="['/parent']">
                                            <i class="la la-undo"></i> {{'button.back' | translate }}
                                        </button>
                                        <button type="submit" class="btn btn-success">
                                            <i class="la"
                                               [ngClass]="{'la-spin la-spinner': formLoading, 'la-check': !formLoading}"></i> {{'button.edit' | translate }}
                                        </button>
                                    </div>
                                </form>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
        </div>

    </div>
</div>


