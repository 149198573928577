<div *ngFor="let alert of alerts" >
    <a style="display: none;" style="display: none;"class="close" (click)="removeAlert(alert)" *ngIf="!alert.notRemovable">&times;</a>
    <span style="display: none;" [innerHTML]="alert.message"></span>
    <ngb-alert class="alert-icon-left {{cssClass(alert)}}"  [dismissible]="false"
               [type]="alert.type" role="alert">
        <span class="alert-icon"><i class="{{alert.type == 0 ? 'la la-check' : 'la-info'}}"></i></span>

        <li *ngFor="let error of alert.message; index as i">{{ error | translate}}</li>
        <a class="close" (click)="removeAlert(alert)" *ngIf="!alert.notRemovable">&times;</a>
    </ngb-alert>
</div>

