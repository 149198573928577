import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BusSeat } from '../models/bus.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusService {

  baseURL = 'assets/data';
  loadBusSeatURL = null;
  constructor(private http: HttpClient) {
    this.loadBusSeatURL = `${this.baseURL}/bus/bus-seat-positions.json`;
  }

  getBusSeatMst(): Observable<BusSeat[]> {
    return this.http.get(this.loadBusSeatURL).pipe(
      map((res: any) => {
        var busSeats = [];
        for (let item of res.busSeats) {
          busSeats.push(this.parseResponseBusSeat(item));
        }
        return busSeats;
      })
    );
  }

  parseResponseBusSeat(data: any): BusSeat {
    const busSeat = new BusSeat();
    busSeat.fill(data);
    return busSeat;
  }
}

