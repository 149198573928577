<div class="content-wrapper">
        <div class="content-body">
            <section >
                <div class="col-md-12 col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0">
                        <div class="card border-grey-3 border-lighten-2 m-0">
                            <div class="card-header border-0 bg-light-blue">
                                <div class="card-title text-center">
                                    <img src="assets/jsh/images/login/login_top.png" class="w-75" alt="login">
                                </div>
                            </div>
                            <div class="card-content px-1 py-1 m-0">
                                <app-message></app-message>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span class="text-weight">Log in using</span></h6>
                                <div class="text-center">
                                    <button (click)="signinWithGoogle()" class="btn btn-social-icon mr-1 mb-1 btn-outline-google"><span class="la la-google"></span></button>
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span class="text-weight">Log in to JSH Bus System</span></h6>
                                <div class="card-body">
                                    <ngb-alert *ngIf="alert.show" class="alert-icon-left" [dismissible]="true"
                                               [type]="alert.type" (close)="alert.show=false" role="alert">
                                        <span class="alert-icon"><i [class]="alert.icon"></i></span>
                                        {{ alert.content}}
                                    </ngb-alert>
                                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="signIn()">
                                        <div class="input-group py-1">
                                            <div class="input-group-prepend" >
                                                <span class="input-group-text form-control " [ngClass]="{ 'focusInput': (focusInEmail && !submitted) || (focusInEmail && submitted && !f.email.errors), 'is-invalid': submitted && f.email.errors}">
                                                    <i class="la la-user"></i></span>
                                            </div>
                                            <input type="text" formControlName="email"
                                                class="form-control border-left-0 shadow-none"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="user-name"
                                                placeholder="{{'login.user' | translate}}" required (focus)="focusEmail()" (focusout)="focusOutEmail()">
                                            <app-validation-error [field]="'login.user'" [submitted]="submitted"
                                                                  [control]="f.email">
                                            </app-validation-error>

                                        </div>
                                        <div class="input-group py-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text form-control" [ngClass]="{ 'focusInput': (focusInPassword && !submitted) || (focusInPassword && submitted && !f.password.errors), 'is-invalid': submitted && f.password.errors}"><i class="la la-key"></i></span>
                                            </div>
                                            <input [type]="togglePassword ? 'text' : 'password'"
                                                placeholder="{{'login.password' | translate}}" formControlName="password"
                                                class="form-control border-left-0 border-right-0 shadow-none"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  
                                                (focus)="focusPassword()" (focusout)="focusOutPassword()">
                                            <div class="input-group-append">
                                                <span class="input-group-text form-control" [ngClass]="{ 'focusInput': (focusInPassword && !submitted) || (focusInPassword && submitted && !f.password.errors), 'is-invalid': submitted && f.password.errors}">
                                                    <i class="la" [ngClass]="{
                                                        'la-eye-slash': togglePassword,
                                                        'la-eye': !togglePassword
                                                      }" (click)="togglePasswordText()"></i>
                                                </span>
                                            </div>
                                            <app-validation-error [field]="'login.password'" [submitted]="submitted"
                                                                  [control]="f.password">
                                            </app-validation-error>

                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-6 col-12 text-center text-sm-left">
                                                <div class="custom-control custom-checkbox mb-1">
                                                    <input type="checkbox" class="custom-control-input"
                                                        formControlName="rememberMe" name="remember-me"
                                                        id="remember-me">
                                                    <label class="custom-control-label" for="remember-me">{{'login.remember_me' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 float-sm-left text-center text-sm-right"><a
                                                href="/forgot-password" class="card-link">{{'login.forgot_password' | translate}}</a>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-unlock" *ngIf="!submitted"></i> Login
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
