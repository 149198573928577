import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {UserProfileService} from '../services/user-profile.service';
import {User} from '../models/user.model';
import { KEY_USER_INFO } from 'src/app/utils/const';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private userProfileService: UserProfileService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url = state.url;
    const validRequest = this.checkLogin(url);
    if (validRequest) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }

  checkLogin(url: string): boolean {
    const user = localStorage.getItem(KEY_USER_INFO);
    if (user) {
      return true;
    }
    this.authService.redirectUrl = url;
    return false;
  }
}
