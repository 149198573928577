import {Observable, BehaviorSubject, of, throwError} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {
    PaginateOption,
    PaginateData,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import {BusAssistant, BusAssistantCreate, BusAssistantResult, BusAssistantUpdate} from '../models/bus-assistant.model';
import {catchError, map} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import {AppHttpClient} from '../services/app-http-client.service';

@Injectable()
export class BusAssistantRepository extends Repository
    implements PaginationRepository {

    paginate(option: PaginateOption): any {
        return this.httpClient.get('/assistant/list', option).pipe(
            map((response: any) => {
                const BusAssistants = [];
                const res = response.records.listAssistant;
                if (res.assistants) {
                    for (const item of res.assistants) {
                        BusAssistants.push(this.parseResponse(item));
                    }
                }
                return {
                    data: BusAssistants,
                    page: res.currentPage || 0,
                    total: res.totalRecord,
                    pageSize: option.displayItem,
                };
            })
        );
    }


    getInfo(id: Number): Observable<BusAssistant> {
        return this.httpClient.get('/assistant/detail/' + id).pipe(
            map((res: any) => {
                return this.parseResponse(res.records.assistant);
            })
        );
    }

    createBusAssistant(data: BusAssistantCreate): Observable<BusAssistantResult> {
        data.status = Number(data.status);
        data.gender = Number(data.gender);
        // @ts-ignore
        return this.httpClient
            .post('/assistant/create', data, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
                })
            });
    }

    updateBusAssistant(data: BusAssistantUpdate): Observable<BusAssistantResult> {
        data.status = Number(data.status);
        data.gender = Number(data.gender);
        // @ts-ignore
        return this.httpClient
            .put('/assistant/update', data);
    }


    deleteBusAssistant(id: number): Observable<any> {
        return this.httpClient.delete('/assistant/delete/' + id, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
            })
        });
    }

    parseResponse(data: any): BusAssistant {

        let busAssistant: BusAssistant;
        busAssistant = new BusAssistant();
        busAssistant.fill(data);
        return busAssistant;
    }

}
