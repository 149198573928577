<footer id="footer" class="footer footer-static footer-light navbar-border navbar-shadow" *ngIf="showFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2019 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://themeforest.net/user/JSHANOI/portfolio?ref=JSHANOI"
        target="_blank">JSHANOI </a></span></p>
</footer>

<footer id="footer" class="footer fixed-bottom footer-light navbar-border navbar-shadow" *ngIf="fixedFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2019 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://themeforest.net/user/JSHANOI/portfolio?ref=JSHANOI"
        target="_blank">JSHANOI </a></span></p>
</footer>

<footer id="footer" class="footer fixed-bottom footer-dark navbar-border navbar-shadow" *ngIf="darkFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2019 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://themeforest.net/user/JSHANOI/portfolio?ref=JSHANOI"
        target="_blank">JSHANOI </a></span></p>
</footer>
