import { Model } from './model';

export class SchoolAdmin extends Model {
    fullname: string;
    fullnameHira: string;
    fullnameRoma: string;
    email: string;
    token: string;
    status: string;
}
export class SchoolAdminUpdate extends Model {
    fullname: string;
    fullnameHira: string;
    fullnameRoma: string;
    email: string;
    token: string;
}
export class SchoolAdminCreate extends Model {
    fullname: string;
    fullnameHira: string;
    fullnameRoma: string;
    email: string;
    token: string;
}
