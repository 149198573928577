import {Model} from './model';

export class BusAssistant extends Model {
    fullname: string;
    gender: number;
    phoneNumber: string;
    status: number;
    email: string;
    password: string;
    fullnameHira: string;
    fullnameRoma: string;
    isReferJourney: boolean;
    isBussy?: boolean = false;
}

export class BusAssistantResult extends Model {
    success: boolean;
    data: any;
}

export class BusAssistantCreate extends Model {
    fullname: string;
    gender: number;
    password: string;
    fullnameHira: string;
    fullnameRoma: string;
    phoneNumber: string;
    status: number;
    email: string;
}
export class BusAssistantUpdate extends Model {
    fullname: string;
    gender: number;
    fullnameHira: string;
    fullnameRoma: string;
    phoneNumber: string;
    status: number;
    email: string;
}

