import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
  PaginateOption,
  PaginateData,
  PaginationRepository,
} from '../interfaces/pagination.interface';
import { BusDevice } from '../models/bus-device.model';
import { map } from 'rxjs/operators';

@Injectable()
export class BusDeviceRepository extends Repository  implements PaginationRepository {
  paginate(option: PaginateOption): Observable<PaginateData> {
    return this.httpClient
    .get('/busdevice/list', option)
    .pipe(
      map((res: any) => {
        const busdivces = [];
        for (const item of res.records.listBusDevice.busDevices) {
          busdivces.push(this.parseResponse(item));
        }
        return {
          data: busdivces,
          page: res.records.listBusDevice.currentPage || 0,
          totalPage: res.records.listBusDevice.totalPage,
          total: res.records.listBusDevice.totalRecord,
          pageSize: option.displayItem,
        };
      })
    );
  }

  settingBusDevice(data: any): Observable<BusDevice> {
    return this.httpClient.post('/busdevice/settings', data);
  }
  createBusDevice(data: any): Observable<BusDevice> {
    return this.httpClient.post('/busdevice/create', data);
  }

  updateBusDevice(data: any): Observable<BusDevice> {
    return this.httpClient.put('/busdevice/update', data);
  }

  detailBusDevice(id: any): Observable<BusDevice> {
    return this.httpClient.get('/busdevice/detail/' + id).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.busDevice);
      })
    );
  }

  deleteBusDevice(id: any): Observable<BusDevice> {
    return this.httpClient.delete('/busdevice/delete/' + id);
  }

  parseResponse(data: any): BusDevice {
    const busDevice = new BusDevice();
    busDevice.fill(data);
    return busDevice;
  }
}
