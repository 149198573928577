import {Observable} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {
    PaginateOption,
    PaginateData,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import {Parent} from '../models/parent.model';
import {map} from 'rxjs/operators';
import {HttpHeaders, HttpInterceptor} from '@angular/common/http';

@Injectable()
export class ParentRepository extends Repository
    implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient.get('/parent/list', option).pipe(
            map((response: any) => {
                const parents = [];
                let res = response.records.listParents;
                if (res.parents) {
                    for (const item of res.parents) {
                        parents.push(this.parseResponse(item));
                    }
                }
                return {
                    data: parents,
                    page: res.currentPage || 0,
                    total: res.totalRecord,
                    pageSize: option.displayItem,
                };
            })
        );
    }

  getInfo(id: string): Observable<Parent> {
    return this.httpClient.get('/parent/detail/' + id, {}).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.parent);
      })
    );
  }

    createParent(data: any): Observable<Parent> {
        data.status = Number(data.status);
        delete data['id'];
        data.relation = Number(data.relation);
        return this.httpClient
            .post('/parent/create', data);
            // .pipe(
            //     map((res: any) => {
            //         return this.parseResponse(res.records.parent);
            //     })
            // );
    }

    updateParent(id: string, parent: any): Observable<any> {
        parent.status = Number(parent.status);
        return this.httpClient.put('/parent/update', parent);
    }

    deleteParent(id: number): Observable<any> {
        return this.httpClient.delete('/parent/delete/' + id, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
            })
        });
    }

    deleteMultiParent(ids: any): Observable<any> {
        return this.httpClient.post('/parent/deleteparentselected', ids);
    }

    parseResponse(data: any): Parent {
        const parent = new Parent();
        parent.fill(data);
        return parent;
    }
}
