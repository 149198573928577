export const DEFAULT_PAGE_SIZE = 25;
export const RESULT_CODE_SUCCESS = 1000;
export const DEFAULT_REDIRECT_LINK = '/student';
export const KEY_USER_INFO = 'app_schoolAdmin';
export const DEFAULT_LANGUAGE = 'jp';
export const KEY_LANGUAGE_CODE = 'language_code';
export const LIST_LANGUAGE = [
    {
        key: 'jp',
        text: 'language.ja',
    },
    {
        key: 'vn',
        text: 'language.vi',
    },
    {
        key: 'gb',
        text: 'language.en',
    },
];
export const BUS_JOURNEY_NOT_YET = 0;
export const BUS_JOURNEY_RUNNING = 1;
export const BUS_JOURNEY_STOP = 2;
export const DELETE_ALL_STUDENT = 3;
