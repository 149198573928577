import { PatternJourneyRepository } from './repositories/pattern-journey.repository';
import { NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig
} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {
  NgbModule,
  NgbCarouselConfig,
  NgbModalConfig,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserRepository } from './repositories/user.repository';
import { SchoolAdminRepository } from './repositories/school-admin.repository';
import { AuthGuard } from './guards/auth.guard';
import { AlertComponent } from './_helpers/alert.component';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { TableApiService } from './services/table-api.service';
import { ApplicationApiService } from './services/application-api.service';

import { ParentRepository } from './repositories/parent.repository';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import localeJa from '@angular/common/locales/ja';
registerLocaleData(localeVi);
registerLocaleData(localeJa);

// Routing
import { routing } from './app.routing';
// Components
import { AppComponent } from './app.component';
import { SettingsModule } from './components/layout/settings/settings.module';
import { ThemeSettingsConfig } from './components/layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './components/layout/settings/menu-settings.config';
import { HeaderComponent } from './components/layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './components/layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './components/layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './components/layout/header/full-layout-navbar/full-layout-navbar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './components/layout/navigation/navigation.component';
import { PublicLayoutComponent } from './components/layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './components/layout/private-layout/private-layout.component';
import { LoginComponent } from './components/auth/login';
import { NavbarService } from './services/navbar.service';
import { VerticalnavComponent } from './components/layout/navigation/verticalnav/verticalnav.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { PartialsModule } from './components/pages/partials/partials.module';
import { BreadcrumbModule } from './components/layout/breadcrumb/breadcrumb.module';
import { BlockTemplateComponent } from './components/layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { MatchHeightModule } from './components/pages/partials/general/match-height/match-height.module';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { ToastrModule } from 'ngx-toastr';

import { AppHttpClient } from './services/app-http-client.service';
import { HttpErrorHandler } from './services/http-error-handler.service';

import { BaseComponent } from './components/core/base/base.component';
import { Error404Component } from './components/pages/404/error404.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { BusStopRepository } from './repositories/bus-stop.repository';
import { BusAssistantRepository } from './repositories/bus-assistant.repository';
import { NotificationRepository } from './repositories/notification.repository';
import { RouteGroupRepository } from './repositories/route-group.repository';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultiLanguageComponent } from './components/layout/header/multi-language/multi-language.component';
import {MultiLanguageService} from './services/multi-language.service';
import { FormFragmentComponent } from './components/share/form-fragment/form-fragment.component';
import { DragulaModule } from 'ng2-dragula';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { environment } from './../environments/environment';
import { MessageComponent } from './components/core/message/message.component';
import { BusRepository } from './repositories/bus.repository';
import { BusDriverRepository } from './repositories/bus-driver.repository';
import { AlertFlashModule } from '../app/_alert/alert-flash.module';
import { BusRouteRepository } from './repositories/bus-route.repository';
import { GroupRepository } from './repositories/group.repository';
import { AbsentRepository } from './repositories/absent.repository';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { JourneyCalendarComponent } from './components/pages/journey/journey-calendar/journey-calendar.component';
import { BusService } from './services/bus.service';
import {ValidationErrorModule} from './components/core/validation-error/validation-error.module';
import {JourneyLogRepository} from './repositories/journey-log.repository';
import {CalendarAbsentRepository} from './repositories/calendar-absent.repository';
import {GradeRepository} from './repositories/grade.repository';
import { DatePipe } from '@angular/common';
import {JourneyRepository} from './repositories/journey.repository';
import {ManageDocumentRepository} from './repositories/manage-document.repository';
import {BusDeviceRepository} from './repositories/bus-device.repository';
import {SystemlogsRepository} from './repositories/systemlogs.repository';
import {ManageFileapkRepository} from './repositories/manage-fileapk.repository';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
// @ts-ignore
@NgModule({
    imports: [
        BrowserModule,
        PartialsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ChartsModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgbModule,
        FormsModule,
        AlertFlashModule,
        routing,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        ToastrModule.forRoot(),
        NgxSpinnerModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
        }),
        DragulaModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
                deps: [HttpClient]
            }
        }),
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        SocialLoginModule,
        ValidationErrorModule
    ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    FooterComponent,
    AppNavigationComponent,
    AlertComponent,
    LoginComponent,
    VerticalnavComponent,
    BlockTemplateComponent,
    FullLayoutComponent,
    BaseComponent,
    MultiLanguageComponent,
    FormFragmentComponent,
    MessageComponent,
    JourneyCalendarComponent,
    Error404Component,
    ChangePasswordComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  providers: [
    AuthGuard,
    AlertService,
    NavbarService,
    TableApiService,
    ApplicationApiService,
    ParentRepository,
    BusStopRepository,
    UserRepository,
    SchoolAdminRepository,
    BusRepository,
    BusDriverRepository,
    BusRouteRepository,
    RouteGroupRepository,
    GroupRepository,
    AbsentRepository,
    AppHttpClient,
    HttpErrorHandler,
    AuthService,
    BusAssistantRepository,
    NotificationRepository,
    BusRouteRepository,
    PatternJourneyRepository,
    JourneyLogRepository,
    CalendarAbsentRepository,
    ManageDocumentRepository,
    ManageFileapkRepository,
    SystemlogsRepository,
    GradeRepository,
    BusDeviceRepository,
    MultiLanguageService,
    NgbActiveModal,
    BusService,
    JourneyRepository,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    DatePipe,
    NgbCarouselConfig,
    NgbModalConfig,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            ),
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  entryComponents: [
    BlockTemplateComponent
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
