import { AbstractControl } from '@angular/forms';

export class CustomValidators {
  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;
    // compare is the password math
    if (confirmPassword !== '' && password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }

  static dateTimeValidator(control: AbstractControl) {
    const date: string = control.get('timeSend').value;
    const time: string = control.get('time').value;
    // compare is the password math
    /*if ((date !== '' && time === '') || (date === '' && time !== '')) {
      // if they don't match, set an error in our confirmPassword form control
      console.log(date,time);
      control.get('timeSend').setErrors({required: true});
    }*/
  }
}
