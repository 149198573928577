import {Observable} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {
    PaginateOption,
    PaginateData,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import {Systemlogs} from '../models/systemlogs.model';
import {map} from 'rxjs/operators';
import {HttpHeaders, HttpInterceptor} from '@angular/common/http';

@Injectable()
export class SystemlogsRepository extends Repository
    implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient.get('/systemlogs/list', option).pipe(
            map((response: any) => {
                const systemLogs = [];
                let res = response.records.listSystemLogs;
                if (res.systemLogs) {
                    for (const item of res.systemLogs) {
                        systemLogs.push(this.parseResponse(item));
                    }
                }
                return {
                    data: systemLogs,
                    page: res.currentPage || 0,
                    total: res.totalRecord,
                    pageSize: option.displayItem,
                };
            })
        );
    }

    parseResponse(data: any): Systemlogs {
        const systemlogs = new Systemlogs();
        systemlogs.fill(data);
        return systemlogs;
    }
}
