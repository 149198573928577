import { Component, Input, OnInit } from '@angular/core';
import { ViewChild, TemplateRef } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as fs from 'file-saver';
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  isSameDay,
  isSameMonth,
  endOfWeek,
  format,
} from 'date-fns';
import {
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarEvent,
  CalendarEventAction,
  CalendarMonthViewDay
} from 'angular-calendar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BaseComponent } from 'src/app/components/core/base/base.component';
import { ComponentService } from 'src/app/services/component.service';
import { JourneyRepository } from 'src/app/repositories/journey.repository';
import { JourneyCalendarDetailComponent } from './journey-calendar-detail/journey-calendar-detail.component';
import { JourneyCalendarAddComponent } from './journey-calendar-add/journey-calendar-add.component';
import { PopupDeleteComponent } from '../popup-delete/popup-delete.component';
import { PopupRefreshComponent } from '../popup-refresh/popup-refresh.component';
import { MultiLanguageService } from '../../../../services/multi-language.service';
import moment from 'moment';
import { Workbook } from 'exceljs';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#FF5722',
    secondary: '#FDF1BA'
  }
};
const lang: any = {
  gb: 'en',
  jp: 'ja',
  vn: 'vi',
}
interface EventGroupMeta {
  type?: string;
  directionType?: number;
  startTime?: string;
  routeName?: string;

}
@Component({
  selector: 'app-journey-calendar',
  templateUrl: './journey-calendar.component.html',
  styleUrls: ['./journey-calendar.component.css']
})
export class JourneyCalendarComponent extends BaseComponent implements OnInit {
  public breadcrumb: any = {
    'mainlabel': 'page.journey_calendar',
    'links': [
      {
        'name': 'page.home',
        'isLink': true,
        'link': '/'
      },
      {
        'name': 'page.journey_calendar',
        'isLink': false,
        'link': '#'
      }
    ]
  };
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
  };

  @BlockUI('events') blockUIEvents: NgBlockUI;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('modalAddJourney', { static: true }) modalAddJourney: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  newEvent: CalendarEvent;
  viewDate: Date = new Date();
  workDate: any;
  today: string;
  activeDayIsOpen = false;
  locale: string;
  @Input() formLoading;
  submitted = false;
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];
  refresh: Subject<any> = new Subject();
  journeyCalendar$: Observable<CalendarEvent[]>;

  events: CalendarEvent[];

  groupedSimilarEvents: CalendarEvent[] = [];

  constructor(
    private modalService: NgbModal,
    protected repository: JourneyRepository,
    protected service: ComponentService,
    protected mutilService: MultiLanguageService
  ) {
    super(service);
  }

  ngOnInit() {
    this.mutilService.sharedLang.subscribe(v => {
      if (v) {
        this.locale = lang[v];
      }
    });
    this.locale = lang[localStorage.getItem('language_code')];
    this.loadJourneyCalendar();
    const date = new Date;
    this.today = moment().format('YYYY-MM-DD');
  }

  loadJourneyCalendar(): void {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];
    let params = {};
    if (format(getStart(this.viewDate), 'YYYY-MM-DD') === format(getEnd(this.viewDate), 'YYYY-MM-DD')) {
      const dateFormYear = format(getStart(this.viewDate), 'YYYY');
      const month_pattern = format(getStart(this.viewDate), 'MM');
      const dateToYear = format(getEnd(this.viewDate), 'YYYY');
      let dateToDay = format(getEnd(this.viewDate), 'DD');
      if (parseInt(month_pattern) === 1 || parseInt(month_pattern) === 3 || parseInt(month_pattern) === 5 || parseInt(month_pattern) === 7 || parseInt(month_pattern) === 8 || parseInt(month_pattern) === 10 || parseInt(month_pattern) === 12) {
        dateToDay = '31';
      } else if (parseInt(month_pattern) === 2) {
        dateToDay = '28';
      } else {
        dateToDay = '30';
      }
      params = {
        "DateFrom": dateFormYear + '-' + month_pattern + '-01',
        "DateTo": dateToYear + '-' + month_pattern + '-' + dateToDay
      };
    } else {
      params = {
        "DateFrom": format(getStart(this.viewDate), 'YYYY-MM-DD'),
        "DateTo": format(getEnd(this.viewDate), 'YYYY-MM-DD')
      };
    }

    this.groupedSimilarEvents = [];
    let buffetData = new Subject<CalendarEvent[]>();

    this.repository.getJourneyCalendar(params).subscribe(data => {
      const journey = data.map(v => {
        return this.parseJourneyCalendar(v);
      });
      buffetData.next(journey);
    });
    this.journeyCalendar$ = buffetData.asObservable();
  }

  beforeMonthViewRender({
    body,
  }: {
    body: CalendarMonthViewDay<EventGroupMeta>[];
  }): void {
    // month view has a different UX from the week and day view so we only really need to group by the type
    body.forEach((cell) => {
      const groups = {};
      cell.events.forEach((event: any) => {
        if (event.meta.directionType === 1) {
          groups['arrival'] = groups['arrival'] || [];
          groups['arrival'].push(event);
        } else {
          groups['departure'] = groups['departure'] || [];
          groups['departure'].push(event);
        }
      });
      cell['eventGroups'] = Object.entries(groups);
    });
  }

  deleteAllJourney(data): void {
    const modalRef = this.modalService.open(PopupDeleteComponent, { windowClass: 'animated fadeInDown' });
    modalRef.componentInstance.data = { data, modalRef };
    modalRef.componentInstance.status.subscribe(v => {
      if (v === 1) {
        this.repository.deleteJourneySchedule(data.id, data.workDate).subscribe(_ => {
          const content = this.service.translate.instant('message.delete_success', { value: data.patternName });
          this.service.alertFlashService.success([content], this.optionsAlert);
          this.loadJourneyCalendar();
        }, error => {
          this.service.alertFlashService.error(error, this.optionsAlert);
        });
      }
      modalRef.close();
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    const modalRef = this.modalService.open(JourneyCalendarDetailComponent, { windowClass: 'animated fadeInDown', size: 'lg' });
    modalRef.componentInstance.dataJourney = event.meta;
    modalRef.componentInstance.sumstudent = this.countStudent(event.meta.journeys);
  }
  countStudent(journeys: any) {
    let sum: number = 0;
    journeys.forEach(a => sum += a.numberOfStudents);
    return sum;
  }
  handleJourney(action: string): void {
    const modalRef = this.modalService.open(JourneyCalendarAddComponent, { windowClass: 'animated fadeInDown', size: 'lg' });
    modalRef.componentInstance.listJourneyCalendar.subscribe(data => {
      const journey = data.datajourney.map(v => {
        return this.parseJourneyCalendar(v);
      });
      this.journeyCalendar$ = of([...journey]);
      this.closeOpenMonthViewDay();
    });
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  closeOpenMonthViewDay() {
    this.loadJourneyCalendar();
    this.activeDayIsOpen = false;
  }
  onReset(): void {

  }
  addJourney(): void {
    this.handleJourney('Add journey');
    this.refresh.next();
  }

  async exportJourney(): Promise<void> {
    const tableHeader = ['日', '曜', '登校バスの時間', '下校バスの時間'];
    const days = {
      Monday: "月曜日",
      Tuesday: "火曜日",
      Wednesday: "水曜日",
      Thursday: "木曜日",
      Friday: "金曜日",
      Saturday: "土曜日",
      Sunday: "日曜日",
    }
    let workbook = new Workbook();
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];
    const year = format(getStart(this.viewDate), 'YYYY');
    const month = format(getStart(this.viewDate), 'MM');
    let worksheet = workbook.addWorksheet(`${year}年${month}月`);
    let headerRow = worksheet.addRow(tableHeader);
    // Cell Style : Fill and Border
    headerRow.eachCell({ includeEmpty: true }, (cell, i) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '34b886' },
        bgColor: { argb: 'FF0000FF' },
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    headerRow.font = { size: 16, bold: true };

    const data = await this.repository.exportJourneySchedule().toPromise();
    
    data.forEach((v) => {
      worksheet.addRow([v.dateMonth, days[v.dayDate], v.journeyPatternD1, v.journeyPatternD2]);
    });

    worksheet.eachRow({ includeEmpty: true }, row => {
      row.eachCell({ includeEmpty: true }, (cell, i) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.alignment = { vertical: 'middle', wrapText: true };
      })
    });

    worksheet.getColumn(1).width = 5;
    worksheet.getColumn(2).width = 13;
    worksheet.getColumn(3).width = 50;
    worksheet.getColumn(4).width = 50;

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const filename = `スクールバス運行カレンダー【${year}/${month}】＿ ${format(new Date(), 'YYYYMMDDHm')}.xlsx`;
      fs.saveAs(blob, filename);
    });
  }

  refreshJourneyCalendar(pattern_id, workDate, pattern_name): void {
    const data = {
      'workDates': [moment(workDate).format('YYYY-MM-DD')],
      'journeyPatternId': [pattern_id]
    };
    const modalRef = this.modalService.open(PopupRefreshComponent, { windowClass: 'animated fadeInDown' });
    modalRef.componentInstance.data = { data, modalRef };
    modalRef.componentInstance.status.subscribe(v => {
      if (v === 1) {
        this.repository.createJourneyCalendar(data)
          .subscribe(_ => {
            const content = this.service.translate.instant('message.refresh_journey_success', { value: pattern_name });
            this.service.alertFlashService.success([content], this.optionsAlert);
            this.loadJourneyCalendar();
          }, (error) => {
            this.catchError(error);
          });
      }
      modalRef.close();
    });
  }
  isToday(workDate): boolean {
    const wDate = moment(workDate).format('YYYY-MM-DD');
    return moment(this.today).isSameOrBefore(moment(wDate));
  }
  catchError(error: any): void {
    this.service.alertFlashService.error(error, this.optionsAlert);
  }

  reloadEvents(event) {
    this.blockUIEvents.start('Loading..');

    setTimeout(() => {
      this.blockUIEvents.stop();
    }, 2500);
  }

  private parseJourneyCalendar(data: any): CalendarEvent {
    let title = '';
    title += data.patternName;
    title += ':' + data.journeys.length;
    const actions: CalendarEventAction[] = [
      {
        label: '<i class="feather ft-x"></i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          const modalRef = this.modalService.open(PopupDeleteComponent, { windowClass: 'animated fadeInDown' });
          modalRef.componentInstance.data = { data, modalRef };
          modalRef.componentInstance.status.subscribe(v => {
            if (v === 1) {
              this.repository.deleteJourneySchedule(data.id, data.workDate).subscribe(_ => {
                const content = this.service.translate.instant('message.delete_success', { value: data.patternName });
                this.service.alertFlashService.success([content], this.optionsAlert);
                this.loadJourneyCalendar();
              }, error => {
                this.service.alertFlashService.error(error, this.optionsAlert);
              });
            }
            modalRef.close();
          });
        }
      }
    ];
    const journeyCalendar: CalendarEvent = {
      start: startOfDay(new Date(data.workDate)),
      end: endOfDay(new Date(data.workDate)),
      title: title,
      color: (data.directionType === 1) ? colors.blue : colors.yellow,
      actions: actions,
      allDay: true,
      meta: data
    };
    return journeyCalendar;
  }
}
