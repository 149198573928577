import {Router, NavigationEnd} from '@angular/router';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
// import {TranslocoService} from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messageDisplayStatus = new Subject<any>();
  private popupMessageDisplayStatus = new Subject<any>();
  public message: string;
  private flashMessage: string;
  public type: 'success';
  showMessage$ = this.messageDisplayStatus.asObservable();
  showPopupMessage$ = this.popupMessageDisplayStatus.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.flashMessage) {
        this.showMessage(this.flashMessage, this.type);
        this.flashMessage = null;
      }
    });
  }

  flash(message: string) {
    this.flashMessage = message;
  }

  showMessage(message: string, type?: 'success') {
    // this.message = this.translateService.translate(message);
    this.type = type;
    this.messageDisplayStatus.next(new Date());
  }

  hideMessage() {
    this.messageDisplayStatus.next(false);
  }

  showPopupMessage(message: string) {
    // this.message = this.translateService.translate(message);
    this.popupMessageDisplayStatus.next(new Date());
  }

  hidePopupMessage() {
    this.popupMessageDisplayStatus.next(false);
  }
}
