import {Model} from './model';
export class ManageFileapk extends Model {
    id: string;
    versionName: string;
    versionCode: string;
    releaseNote: string;
    forceUpdate: string;
    filePath: string;
    files: [];
    uploadedFiles: [];
    uploadDate: string;
    apkUrl: string;
}

