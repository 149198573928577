import { Model } from './model';
import { PickupPoint } from './pickup-point.model';
import { Grade } from './grade.model';
import { Parent } from './parent.model';
import { Student } from './student.model';


export class Absent extends Model {
  studentId: number;
  parentId: number;
  pickupPoint: PickupPoint;
  grade?: Grade;
  student?: Student;
  fullname: string;
  fullnameHira: string;
  fullnameRoma: string;
  parentName: string;
  parentEmail: string;
  parentPhoneMain: string;
  parentPhoneSub: string;
  pickupPointName: string;
  gradeName?: string;
  parent: Parent;
  gender: Number;
  directionType: number;
  reasonDeparture: string;
  reasonArrival: string;
  applyDate: any;
}
export class AbsentCreate extends Model {
  isDeparture: boolean;
  isArrival: boolean;
  studentIds: number[];
  parentId: number;
  reasonArrival: string;
  reasonDeparture: string;
  directionType: number;
  dateFrom: string;
  dateTo: string;
  dayOfWeek: number;

}