<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section id="horizontal-form-layouts">
                <div class="row">
                    <div class="col-md-12">
                        <m-card>
                            <ng-container mCardHeaderTitle>
                                {{'page.edit_profile' | translate}}
                            </ng-container>
                            <ng-container mCardBody>
                                    <form class="form form-horizontal" [formGroup]="profileForm"
                                          (ngSubmit)="onSubmit()">
                                        <div class="row form-group">
                                            <div class="col-3 text-right">
                                                <label for="email">{{'profile.email' | translate}}<span class="red">*</span></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <input class="form-control" readonly type="email"
                                                           formControlName="email"
                                                           placeholder="{{'profile.email' | translate}}"
                                                           id="email"
                                                           name="email">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group">
                                            <div class="col-3 text-right">
                                                <label for="fullname">{{'profile.fullname' | translate}}<span class="red">*</span></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <input class="form-control" type="text"
                                                           placeholder="{{'profile.fullname' | translate}}"
                                                           id="fullname"
                                                           formControlName="fullname"
                                                           [ngClass]="{ 'is-invalid': submitted && profileForm.controls.fullname.errors }"
                                                           name="fullname">
                                                    <app-validation-error [field]="'profile.fullname'"
                                                                          [submitted]="submitted"
                                                                          [control]="profileForm.controls.fullname">
                                                    </app-validation-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group">
                                            <div class="col-3 text-right">
                                                <label for="fullnameHira">{{'profile.fullname_hira' | translate}}<span class="red">*</span></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <input class="form-control" type="text"
                                                           placeholder="{{'profile.fullname_hira' | translate}}"
                                                           id="fullnameHira"
                                                           [ngClass]="{ 'is-invalid': submitted && profileForm.controls.fullnameHira.errors }"
                                                           formControlName="fullnameHira"
                                                    >
                                                    <app-validation-error [field]="'profile.fullname_hira'"
                                                                          [submitted]="submitted"
                                                                          [control]="profileForm.controls.fullnameHira">
                                                    </app-validation-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group">
                                            <div class="col-3 text-right">
                                                <label for="fullnameRoma">{{'profile.fullname_roma' | translate}}<span class="red">*</span></label>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <input class="form-control" type="text"
                                                           placeholder="{{'profile.fullname_roma' | translate}}"
                                                           id="fullnameRoma"
                                                           [ngClass]="{ 'is-invalid': submitted && profileForm.controls.fullnameRoma.errors }"
                                                           formControlName="fullnameRoma"
                                                    >
                                                    <app-validation-error [field]="'profile.fullname_roma'"
                                                                          [submitted]="submitted"
                                                                          [control]="profileForm.controls.fullnameRoma">
                                                    </app-validation-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group">
                                            <div class="col-3 text-right">
                                                <label for="note">{{'profile.note' | translate}}</label>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <textarea class="form-control" type="text"
                                                              row="5"
                                                              placeholder="{{'profile.note' | translate}}"
                                                              id="note"
                                                              formControlName="note">
                                                    </textarea>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-actions text-center">
                                            <button type="button" class="btn btn-warning mr-1"
                                                    [routerLink]="['/parent']">
                                                <i class="la la-undo"></i> {{'button.back' | translate }}
                                            </button>
                                            <button type="submit" class="btn btn-success">
                                                <i class="la"
                                                   [ngClass]="{'la-spin la-spinner': formLoading, 'la-check': !formLoading}"></i> {{'button.edit' | translate }}
                                            </button>
                                        </div>
                                    </form>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
        </div>

    </div>
</div>


