import {ComponentService} from './../../../services/component.service';
import {BaseComponent} from './../../core/base/base.component';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {AuthService} from 'src/app/services/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  alert: any = {
    show: false,
    icon: 'la la-heart',
    type: 'success',
    content: 'login.failed'
  };
  submitted = false;
  mailSent = false;

  constructor(
    protected service: ComponentService,
    private authService: AuthService,
    private fb: FormBuilder,
    private titleService: Title
  ) {
    super(service);
  }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.mailSent = false;

  }

  sendEmailResetPassword() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    const resetInfo = this.forgotPasswordForm.value;
    this.authService.forgotPassword(resetInfo).subscribe(
      response => {
        const content = this.service.translate.instant('message.send_mail_forgot_password_success');
        this.alert.type = 'success';
        this.alert.icon = 'la la-info';
        this.alert.show = true;
        this.alert.content = content;
        this.mailSent = true;
      },
      error => {
        this.submitted = true;
        this.alert.type = 'danger';
        this.alert.icon = 'la la-info';
        this.alert.show = true;
        this.alert.content = error?.error?.header?.message;
        this.mailSent = false;
        this.catchError(error);
      }
    );
    this.service.message.hideMessage();

  }
  catchError(error: any): void {
    this.service.alertFlashService.error(error, this.optionsAlert);
  }
  get f() {
    return this.forgotPasswordForm.controls;
  }

}
