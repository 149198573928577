import { Injectable } from '@angular/core';
import { Repository } from './repository';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Grade } from '../models/grade.model';

@Injectable()
export class GradeRepository extends Repository {
    getGrades(): Observable<Grade[]> {
        return this.httpClient
        .get('/student/grades')
        .pipe(
            map((res: any) => {
                const grades = [];
                for (const item of res.records.grades) {
                    item.isSelected = true;
                    grades.push(this.parseResponse(item));
                }
                return grades;
            })
        );
    }

    getGradeNotis(): Observable<Grade[]> {
        return this.httpClient
            .get('/student/grades')
            .pipe(
                map((res: any) => {
                    const grades = [];
                    for (const item of res.records.grades) {
                        item.isSelected = false;
                        grades.push(this.parseResponse(item));
                    }
                    return grades;
                })
            );
    }

    parseResponse(data: any): Grade {
        const grade = new Grade();
        grade.fill(data);
        return grade;
    }
}
