import {Component, OnInit, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

    constructor(public translate: TranslateService) {
    }

    clientsAccountsList = [
        'businfo@jsh.edu.vn',
    ];
    currentAccount!: string;
    informText = '';
    @Input() breadcrumb: object;

    ngOnInit() {
        this.processBreadCrumbLinks();
        if (localStorage.getItem('app_schoolAdmin')) {
            const currentUser = JSON.parse(localStorage.getItem('app_schoolAdmin'));
            this.currentAccount = currentUser.email;
        }
        const isClient = this.clientsAccountsList.find((v: string) => {
            return v === this.currentAccount;
        });
        if (environment.production && !isClient) {
            this.informText = 'MÔI TRƯỜNG PRODUCTION, KHÔNG TỰ Ý THÊM, SỬA, XÓA DỮ LIỆU KHI CHƯA ĐƯỢC PHÉP';
        }
    }

    private processBreadCrumbLinks() {
    }
}
