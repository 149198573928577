import { UserProfileService } from './user-profile.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRepository } from '../repositories/user.repository';
import { LocalStorageService } from './storage.service';
import { User } from '../models/user.model';
import { SchoolAdmin } from '../models/school-admin.model';
import { Router } from '@angular/router';
import { UnauthenticatedException } from './http-error-handler.service';
import { RESULT_CODE_SUCCESS } from 'src/app/utils/const';
import { DEFAULT_REDIRECT_LINK } from 'src/app/utils/const';
import { SchoolAdminRepository } from '../repositories/school-admin.repository';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  isLoggedIn = false;
  accessToken = '';
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private userRepository: UserRepository,
    private storageService: LocalStorageService,
    private router: Router,
    private schoolAdminRepository: SchoolAdminRepository,
  ) {
    this.redirectUrl = DEFAULT_REDIRECT_LINK;
  }

  async login(authInfo: any) {
    return this.schoolAdminRepository.login(authInfo).toPromise().then(async (res: any) => {
      if (res.header && Number(res.header.resultCode) === RESULT_CODE_SUCCESS) {
        this.accessToken = res.records.schoolAdmin.token;
        this.setToken(this.accessToken);
        const schoolAdmin = new SchoolAdmin();
        schoolAdmin.fill(res.records.schoolAdmin);
        this.storageService.set('schoolAdmin', JSON.stringify(schoolAdmin));
        this.loggedIn();
      }
      return res;
    });
  }

  async googleLogin(authInfo: any) {
    return this.schoolAdminRepository.googleLogin(authInfo).toPromise().then(async (res: any) => {
      if (res.header && Number(res.header.resultCode) === RESULT_CODE_SUCCESS) {
        this.accessToken = res.records.schoolAdmin.token;
        this.setToken(this.accessToken);
        const schoolAdmin = new SchoolAdmin();
        schoolAdmin.fill(res.records.schoolAdmin);
        this.storageService.set('schoolAdmin', JSON.stringify(schoolAdmin));
        this.loggedIn();
      }
      return res;
    });
  }


  endSession(): void {
    this.isLoggedIn = false;
    this.storageService.unset('access_token');
    this.storageService.unset('schoolAdmin');
    this.router.navigate(['/login']);
  }

  setToken(token: string) {
    if (token) {
      this.storageService.set('access_token', token);
    }
  }


  check(): Promise<any> {
    return new Promise((resolve, reject) => {
      const accessToken = this.storageService.get('access_token');
      if (!accessToken) {
        resolve(false);
      }
      resolve(true);
    });
  }

  loggedIn(): void {
    this.isLoggedIn = true;
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
    }
  }


  forgotPassword(resetInfo: any): Observable<any> {
    return this.userRepository.forgotPassword(resetInfo);
  }

  resetPassword(resetInfo: any): Observable<any> {
    return this.userRepository.resetPassword(resetInfo);
  }

  checkTokenResetPassword(token: string): Observable<any> {
    return this.userRepository.checkTokenResetPassword(token);
  }
}
