import { Model } from './model';
import { BusAssistant } from './bus-assistant.model';
import { Bus } from './bus.model';
import { BusDriver } from './bus-driver.model';
import { BusRoute } from './bus-route.model';

export class Journey extends Model {
  routeId: number;
  busId: number;
  assistantId: number;
  driverId: number;
  activeYear: number;
  activeMonth: number;

  order?: number;
  assistantName?: string;
  directionType?: number;
  driverName?: string;
  licensePlate?: string;
  name?: string;
  numberOfStudents?: string;
  routeName?: string;
}
export class JourneyLogDetail extends Model {
  assistantName: string;
  directionType: number;
  journeyName: string;
  journeyId: number;
  licensePlate: string;
  name: string;
  numberOfStudents: number;
  routeName: string;
  students: any;
  workDate: string;
  patternName: string;
  assistant: BusAssistant;
  bus: Bus;
  driver: BusDriver;
  route: BusRoute;
  pattern: any;
  records: any;
  hasSchedule: boolean;
  checkInType: number;
  checkInTime: string;
  checkOutType: number;
  checkOutTime: string;
  busLocations: any;
}

export class JourneyExport extends Model {
  journeyPatternD1: string;
  journeyPatternD2: string;
  dateMonth: number;
  dayDate: string;
}
