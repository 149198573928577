import {Model} from './model';
import {Student} from './student.model';

export class Parent extends Model {
  email: string;
  address: string;
  phoneNumberMain: string;
  phoneNumberSub: string;
  memoPhoneNumberMain: number;
  memoPhoneNumberSub: number;
  relationName: string;
  fullname: string;
  fullnameHira: string;
  fullnameRoma: string;
  status: any;
  relation: any;
  pickerReplacementName1: string;
  pickerReplacementRelation1: string;
  pickerReplacementName2: string;
  pickerReplacementRelation2: string;
  students: [Student];
}
