import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANGUAGE, LIST_LANGUAGE, KEY_LANGUAGE_CODE} from '../../../../utils/const';
import {MultiLanguageService} from '../../../../services/multi-language.service';

@Component({
    selector: 'app-multi-language',
    templateUrl: './multi-language.component.html',
    styleUrls: ['./multi-language.component.css']
})
export class MultiLanguageComponent<T> implements OnInit {
    public dropdownStatus: any;
    private listLanguage: any;
    private langSelect: string;

    @Input() set internationalization(value: T) {
        if (!value) {
            return;
        }
        this.dropdownStatus = value;
    }

    constructor(private translate: TranslateService,
                private multiLang: MultiLanguageService) {
        this.translate = translate;
        const langSelect = localStorage.getItem(KEY_LANGUAGE_CODE);
        if (langSelect !== null) {
            this.langSelect = langSelect;
        } else {
            this.langSelect = DEFAULT_LANGUAGE;
            localStorage.setItem(KEY_LANGUAGE_CODE, DEFAULT_LANGUAGE);
        }
        this.translate.setDefaultLang(this.langSelect);
        this.listLanguage = LIST_LANGUAGE;
    }
    ngOnInit(): void {

    }

    public useLanguage(lang: string): void {
        this.translate.setDefaultLang(lang);
        this.langSelect = lang;
        this.multiLang.nextLang(lang);
        localStorage.setItem(KEY_LANGUAGE_CODE, lang);
        if (lang === 'jp') {
            document.getElementById('main-menu-navigation').children[3].className = 'active_menu_import';
        } else {
            if (window.location.pathname === '/import') {
                window.location.href = '/student';
            }
            document.getElementById('main-menu-navigation').children[3].className = 'hide_menu_import';
        }
    }
}
