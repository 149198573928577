import {ComponentService} from './../../../services/component.service';
import {BaseComponent} from './../../core/base/base.component';
import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from 'src/app/services/auth.service';
import {Title} from '@angular/platform-browser';
import {RESULT_CODE_SUCCESS} from '../../../utils/const';
import {SchoolAdmin} from '../../../models/school-admin.model';
import {SchoolAdminRepository} from '../../../repositories/school-admin.repository';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BaseComponent implements OnInit {

    profileForm = this.fb.group({
        fullname: ['', [Validators.required]],
        fullnameHira: ['', [Validators.required]],
        fullnameRoma: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        note: ['', []]
    });
    formLoading = false;
    submitted = false;
    breadcrumb: any = {
        'mainlabel': 'page.edit_profile',
        'links': [
            {
                'name': 'page.home',
                'isLink': true,
                'link': '/'
            },
            {
                'name': 'page.edit_profile',
                'isLink': false
            }
        ]
    };
    private schoolAdminId: Number;

    constructor(
        protected service: ComponentService,
        private authService: AuthService,
        private fb: FormBuilder,
        private el: ElementRef,
        private schoolAdminRepository: SchoolAdminRepository
    ) {
        super(service);
    }

    ngOnInit() {
        this.schoolAdminRepository.me().subscribe((res: any) => {
            const schoolAdmin = new SchoolAdmin();
            schoolAdmin.fillable = ['email', 'fullname', 'fullnameHira', 'note', 'fullnameRoma'];
            this.schoolAdminId = res.records.schooladmin.id;
            const data = schoolAdmin.fill(res.records.schooladmin);
            delete data.fillable;
            this.profileForm.setValue(data);
        });
    }

    onSubmit(): void {
        this.formLoading = true;
        this.submitted = true;
        if (this.profileForm.invalid) {
            setTimeout(() => {
                this.formLoading = false;
                for (const key of Object.keys(this.profileForm.controls)) {
                    if (this.profileForm.controls[key].invalid) {
                        const invalidControl = this.el.nativeElement.querySelector('[name="' + key + '"]');
                        invalidControl.focus();
                        break;
                    }
                }
            }, 100);
            return;
        } else {
            const data = this.profileForm.value;
            data.id = this.schoolAdminId;
            this.schoolAdminRepository.updateProfile(data).subscribe(_ => {
                setTimeout(() => {
                    this.formLoading = false;
                }, 100);
                this.schoolAdminRepository.me().subscribe((res: any) => {
                    const storageData = res.records.schooladmin;
                    storageData.fullName = storageData.fullname;
                    this.service.localStorage.set('schoolAdmin', JSON.stringify(storageData));
                });
                const field_name = this.service.translate.instant('profile.update_profile_success');
                this.service.alertFlashService.success([field_name], this.optionsAlert);
            }, (error) => {
                const errors = error.error.errors;
                const arrayErrors = [];
                this.formLoading = false;
                if (errors !== null && errors !== undefined) {
                    Object.keys(errors).forEach((key) => {
                        for (let i = 0; i < errors[key].length; i++) {
                            arrayErrors.push(errors[key][i].toString());
                        }
                    });
                }
                this.service.alertFlashService.error(arrayErrors, this.optionsAlert);
                if (arrayErrors.length === 0) {
                    this.service.alertFlashService.error(['message.unknow_error'], this.optionsAlert);
                }

            });

        }
    }

}
