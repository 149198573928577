import { FragmentComponent } from './../../core/fragment/fragment.component';
import { ComponentService } from './../../../services/component.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-fragment',
  templateUrl: './form-fragment.component.html',
  styleUrls: ['./form-fragment.component.css']
})
export class FormFragmentComponent<T> extends FragmentComponent implements OnInit {

  form: FormGroup;
  @Input() isEdit = false;
  @Output() formComplete = new EventEmitter();
  @Input() set data(value: T) {
    if (!value) {
      return;
    }

    this.form.patchValue(value);
  }
  submitted = false;


  constructor(protected service: ComponentService) {
    super(service);
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.formComplete.emit(this.form.value);
  }

}
