import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
    PaginateOption,
    PaginateData,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import { Journey, JourneyDetail, JourneyExport } from '../models/journey.model';
import { map } from 'rxjs/operators';
import { CalendarEvent } from 'angular-calendar';
import { Parent } from '../models/parent.model';
import { PopupDeleteComponent } from '../components/pages/journey/popup-delete/popup-delete.component';

const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    }
};

@Injectable()
export class JourneyRepository extends Repository implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient
            .get('/journey/list', option)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourney;
                    const joruneys = [];
                    if (res.journeys) {
                        for (const item of res.journeys) {
                            joruneys.push(this.parseResponse(item));
                        }
                    }
                    return {
                        data: joruneys,
                        page: res.currentPage || 0,
                        total: res.totalRecord,
                        pageSize: option.displayItem,
                    };
                })
            );
    }

    getListJourney(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient
            .get('/journey/listjourneycalendar', option)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourneyCalendars;
                    const joruneys = [];
                    if (res.journeySchedules) {
                        for (const item of res.journeySchedules) {
                            joruneys.push(this.parseResponse(item));
                        }
                    }
                    return {
                        data: joruneys,
                        page: res.currentPage || 0,
                        total: res.totalRecord,
                        pageSize: option.displayItem,
                    };
                })
            );
    }

    getListJourneyId(data: any): Observable<JourneyDetail> {
        return this.httpClient.post('/journey/listjourneybypatterns', data);
    }


    getJourneyCalendar(data: any): Observable<CalendarEvent[]> {
        return this.httpClient
            .get('/journey/journeycalendar', data)
            .pipe(
                map((response: any) => {
                    const res = response.records.journeyCalendars;
                    return res;
                })
            );
    }

    createJourneyCalendar(data: any): Observable<Parent> {
        return this.httpClient
            .post('/journeyschedule/createjourneyschedule', data);
    }

    createJourneySchedule(data: any): Observable<any> {
        return this.httpClient.post('/journeyschedule/create', data);
    }

    exportJourneySchedule(): Observable<any> {
        return this.httpClient.get('/journeyschedule/export').pipe(
            map((response: any) => {
              const res = response.records.export;
              const journeys = [];
              if (res.journeyPatterns) {
                for (const item of res.journeyPatterns) {
                    let journey = new JourneyExport()
                    journey.fill(item);
                    journeys.push(this.parseResponse(journey));
                }
              }
              return journeys;
            })
          );;
    }

    deleteJourneySchedule(id: number, workDate: string): Observable<any> {
        return this.httpClient.delete('/journey/deletebypattern/?JourneyPatternId=' + id + '&workDate=' + workDate);
    }

    createJourney(data: any): Observable<Journey> {
        return this.httpClient.post('/journey/create', data);
    }


    updateJourney(data: any): Observable<Journey> {
        return this.httpClient.put('/journey/update', data);
    }

    deleteJourney(id: number): Observable<any> {
        return this.httpClient.delete('/journey/delete/' + id);
    }

    parseResponse(data: any): Journey {
        const student = new Journey();
        student.fill(data);
        return student;
    }

    getInfo(id: Number): Observable<JourneyDetail> {
        return this.httpClient.get('/journey/detail/' + id).pipe(
            map((res: any) => {
                const detail = new JourneyDetail();
                detail.fill(res.records.request);
                return detail;
            })
        );
    }
    getPatternJourney(data: number): Observable<any[]> {
        return this.httpClient
            .get('/journey/getpatternjourney?JourneyPatternId=' + data)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourney.journeyCreate;
                    return res;
                })
            );
    }

    getListJourneyDetail(data: number): Observable<any[]> {
        return this.httpClient
            .get('/journey/listjourneydetail?JourneyPatternId=' + data)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourneyDetails.journeys;
                    return res;
                })
            );
    }
}
