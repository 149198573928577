<div class="modal-header bg-success">
    <h4 class="modal-title white" id="myModalLabel1">{{'journey_calendar.confirm_modal'|translate}}</h4>
    <button type="button" class="close" aria-label="Close"
            (click)="closePopup()">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h5>{{'journey_calendar.message_modal'|translate}}</h5>
    <p>{{'journey_calendar.sub_message_modal'|translate}}</p>
</div>
<div class="modal-footer">
    <button type="button"
            class="btn grey btn-outline-warning"
            (click)="closePopup()">{{'button.cancel'|translate}}</button>
    <button type="button"
            class="btn btn-outline-danger" (click)="onRefreshJourney()">{{ 'button.ok' | translate}}</button>
</div>
