// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  vertical_menu: {
    items: [
      {
        title: 'page.home',
        icon: 'assets/images/vertical-menubar-icon/home.svg',
        page: '/',
      },
      /*{ section: '管理機能', icon: 'la-ellipsis-h' },*/
      {
        title: 'page.student_list',
        icon: 'assets/images/vertical-menubar-icon/students.svg',
        page: '/student'
      },
      {
        title: 'page.parent_list',
        icon: 'assets/images/vertical-menubar-icon/parents.svg',
        page: '/parent'
      },
      {
        title: 'page.import_export',
        icon: 'assets/images/vertical-menubar-icon/import-export.svg',
        page: '/import'
      },
      {
        title: 'page.busstop_list',
        icon: 'assets/images/vertical-menubar-icon/bus-stops.svg',
        page: '/bus-stop'
      },
      {
        title: 'page.route_group',
        icon: 'assets/images/vertical-menubar-icon/routes-group.svg',
        page: '/route_group'
      },
      {
        title: 'page.route_list',
        icon: 'assets/images/vertical-menubar-icon/routes.svg',
        page: '/bus-route'
      },
      {
        title: 'page.assistant_list',
        icon: 'assets/images/vertical-menubar-icon/bus-assistant.svg',
        page: '/bus-assistant'
      },
      {
        title: 'page.bus_list',
        icon: 'assets/images/vertical-menubar-icon/bus.svg',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'page.bus_list',
              page: '/bus'
            },
            {
              title: 'page.bus_device_list',
              page: '/bus-device'
            },
          ]
        }
      },
      {
        title: 'page.driver_list',
        icon: 'assets/images/vertical-menubar-icon/drivers.svg',
        page: '/bus-driver'
      },
      {
        title: 'page.pattern_journey',
        icon: 'assets/images/vertical-menubar-icon/journey-pattern.svg',
        page: '/pattern_journey'
      },
      {
        title: 'page.journey',
        icon: 'assets/images/vertical-menubar-icon/journey.svg',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'page.journey_calendar',
              page: '/journey/calendar'
            },
            {
              title: 'page.journey_list',
              page: '/journey/list'
            },
            {
              title: 'page.journey_log',
              page: '/journey-log'
            },
            {
              title: 'page.journey_pattern_toschool',
              page: '/journey/detail-toschool-default'
            }
          ]
        }
      },
      {
        title: 'page.notification_list',
        icon: 'assets/images/vertical-menubar-icon/notification.svg',
        page: '/notification'
      },
      {
        title: 'page.absent_list',
        icon: 'assets/images/vertical-menubar-icon/absent.svg',
        page: '/absent/list'
      },
      {
        title: 'page.calendar-absent',
        icon: 'assets/images/vertical-menubar-icon/calendar.svg',
        page: '/calendar-absent'
      },
      {
        title: 'page.manage_document',
        icon: 'assets/images/vertical-menubar-icon/manage-document.svg',
        page: '/manage-document'
      },
      {
        title: 'page.manage_fileapk',
        icon: 'assets/images/vertical-menubar-icon/manage-document.svg',
        page: '/manage-fileapk'
      },
      {
        title: 'page.systemlogs',
        icon: 'assets/images/vertical-menubar-icon/manage-document.svg',
        page: '/systemlogs'
      },
      {
        title: 'page.schooladmin',
        icon: 'assets/images/vertical-menubar-icon/home.svg',
        page: '/school-admin'
      }
    ]
  }

};





