<div class="text-left modal-parent-selection">
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">{{ 'page.journey_detail' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mx-auto mb-1">
      <div class="col-md-3">
        <div class="pull-left">
          <b>{{'journey.pattern_journey' | translate }}:</b>
        </div>
      </div>
      <div class="col-md-9">
        {{dataJourney.patternName}}
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-md-12">
        <div class="pull-left">
          <b>{{'page.journey_list' | translate }}:</b>
        </div>
      </div>
      <div class="col-md-12 ">
        <div class="table-responsive table-striped table-bordered base-style">
          <table class="table mb-0">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{'journey.journey_name' | translate}}</th>
                <th scope="col">{{'journey.route' | translate}}</th>
                <th scope="col">{{'journey.bus_assistant' | translate}}</th>
                <th scope="col">{{'journey.bus_driver' | translate}}</th>
                <th scope="col">{{'journey.licensePlate' | translate}}</th>
                <th scope="col">{{'journey.total' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let journey of dataJourney.journeys; index as i">
                <th scope="row">{{ i + 1 }}</th>
                  <td><a routerLink="/journey/detail/{{journey.id}}" target="_blank">{{ journey.name }}</a></td>
                <td>
                  <a routerLink="/bus-route/detail/{{journey.routeID}}" target="_blank">{{ journey.routeName }}</a>
                </td>
                <td>{{ journey.assistantName }} | {{ journey.assistantEmail }} </td>
                <td>{{ journey.driverName }} </td>
                <td>{{ journey.busName }} | {{ journey.licensePlate }}</td>
                <td>{{ journey.numberOfStudents }}</td>
              </tr>
              <tr>
                <td colspan="6">{{'journey.total' | translate}}:</td>
                <td><b>{{sumstudent}}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn grey btn-outline-secondary"
      (click)="activeModal.close('Close click')" ngbAutofocus>{{ 'button.cancel' | translate }}</button>
</div>
