import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
  PaginateOption,
  PaginateData,
  PaginationRepository,
} from '../interfaces/pagination.interface';
import { User } from '../models/user.model';
import { map } from 'rxjs/operators';

@Injectable()
export class UserRepository extends Repository implements PaginationRepository {
  login(authInfo: any) {
    return this.httpClient.post('/schooladmin/login', authInfo);
  }

  socialLogin(authInfo: any) {
    return this.httpClient.post('/schooladmin/google-login', authInfo);
  }

  userInfo() {
    return this.httpClient.get('/check');
  }

  updatePassword(data: any) {
    return this.httpClient.put('/update-password', data);
  }

  checkEmailExist(email: string) {
    return this.httpClient.get('/user/check-email', { email: email });
  }

  forgotPassword(resetInfo: any) {
    return this.httpClient.post('/schooladmin/forgotpassword?email=' + resetInfo.email, resetInfo);
  }

  resetPassword(resetInfo: any) {
    return this.httpClient.post('/schooladmin/resetpassword', resetInfo);
  }

  checkTokenResetPassword(token: any) {
    return this.httpClient.get('/schooladmin/verifytoken?token=' + token, {});
  }

  paginate(option: PaginateOption): Observable<PaginateData> {
    return this.httpClient
      .get('http://localhost:4200/assets/dummyresponse/user/list.json', option)
      .pipe(
        map((res: any) => {
          const users = [];
          for (const item of res.data) {
            users.push(this.parseResponse(item));
          }
          return {
            data: users,
            page: res.page || 0,
            total: res.total,
            pageSize: 5,
          };
        })
      );
  }

  getUser(id: string): Observable<User> {
    return this.httpClient
      .get('http://localhost:4200/assets/dummyresponse/user/detail.json', {
        id,
      })
      .pipe(
        map((res: any) => {
          return this.parseResponse(res.data);
        })
      );
  }

  updateUser(id: string, data: any): Observable<any> {
    return this.httpClient.patch(
      'http://localhost:4200/assets/dummyresponse/user/update.json',
      { data }
    );
  }

  createUser(data: any): Observable<User> {
    return this.httpClient
      .post('http://localhost:4200/assets/dummyresponse/user/create.json', {
        data,
      })
      .pipe(
        map((res: any) => {
          return this.parseResponse(res.data);
        })
      );
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(
      'http://localhost:4200/assets/dummyresponse/user/delete.json'
    );
  }

  parseResponse(data: any): User {
    const user = new User();
    user.fill(data);
    return user;
  }
}
