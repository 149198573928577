import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
  PaginateOption,
  PaginateData,
  PaginationRepository,
} from '../interfaces/pagination.interface';
import { Bus } from '../models/bus.model';
import { map } from 'rxjs/operators';

@Injectable()
export class BusRepository extends Repository  implements PaginationRepository {
  paginate(option: PaginateOption): Observable<PaginateData> {
    return this.httpClient
    .get('/bus/list', option)
    .pipe(
      map((res: any) => {
        const buses = [];
        for (const item of res.records.listBus.buses) {
          buses.push(this.parseResponse(item));
        }
        return {
          data: buses,
          page: res.records.listBus.currentPage || 0,
          totalPage: res.records.listBus.totalPage,
          total: res.records.listBus.totalRecord,
          pageSize: option.displayItem,
        };
      })
    );
  }

  createBus(data: any): Observable<Bus> {
    return this.httpClient.post('/bus/create', data);
  }

  updateBus(data: any): Observable<Bus> {
    return this.httpClient.put('/bus/update', data);
  }

  detailBus(id: any): Observable<Bus> {
    return this.httpClient.get('/bus/detail/' + id).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.bus);
      })
    );
  }

  detailMapBus(id: any): Observable<Bus> {
    return this.httpClient.get('/bus/getlocation/' + id).pipe(
        map((res: any) => {
          return this.parseResponse(res.records.bus);
        })
    );
  }

  currentMapBus(id: any): Observable<Bus> {
    return this.httpClient.get('/buslocation/getcurrentlocation?BusId=' + id).pipe(
        map((res: any) => {
          return this.parseResponse(res.records.busLocation);
        })
    );
  }

  deleteBus(id: any): Observable<Bus> {
    return this.httpClient.delete('/bus/delete/' + id);
  }

  parseResponse(data: any): Bus {
    const bus = new Bus();
    bus.fill(data);
    return bus;
  }
}
