import { Model } from './model';


export class CalendarAbsent extends Model {
  dateinmonth: string;
  holiday: string;
  weekdays: string;
  shiftStatus: number;
  grade: string;
  absentJsh: number;
  holidayReason: string;
  absentgrade: string;
  dayOffStatus: number;
  checkAllGrade: boolean;
}
