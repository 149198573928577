import {User} from './../models/user.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LocalStorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  public userSubject: BehaviorSubject<User>;
  public current: Observable<User>;

  constructor(storageService: LocalStorageService) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(storageService.get('schoolAdmin')));
    this.current = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

}
