import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
  PaginateOption,
  PaginateData,
  PaginationRepository,
} from '../interfaces/pagination.interface';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Group } from '../models/group.model';

@Injectable()
export class GroupRepository extends Repository
  implements PaginationRepository {
  paginate(option: PaginateOption): Observable<PaginateData> {
    return this.httpClient.get('/group/list', option).pipe(
      map((response: any) => {
        const groups = [];
        let res = response.records.listGroups;
        if (res.groups) {
          for (const item of res.groups) {
            groups.push(this.parseResponse(item));
          }
        }
        return {
          data: groups,
          page: res.currentPage || 0,
          total: res.totalRecord,
          pageSize: option.displayItem,
        };
      })
    );
  }


  getInfo(id: Number): Observable<Group> {
    return this.httpClient.get('/group/detail/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
      })
    }).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.group);
      })
    );
  }

  createGroup(data: any): Observable<Group> {
    return this.httpClient
      .post('/group/create', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
        })
      });
  }

  updateGroup(group: any): Observable<any> {
    return this.httpClient.put('/group/update', group);
  }

  deleteGroup(id: number): Observable<any> {
    return this.httpClient.delete('/group/delete/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
      })
    });
  }

  parseResponse(data: any): Group {
    const group = new Group();
    group.fill(data);
    return group;
  }
}
