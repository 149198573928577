
<ngb-datepicker #dp [minDate]="this.minDate" [displayMonths]="1" firstDayOfWeek="7" [dayTemplate]="t">
</ngb-datepicker>

<ng-template #t let-date="date" let-focused="focused">
  <span class="custom-day" (click)="onDateSelection($event,date)"
        [class.focused]="focused"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.selected]="isDateSelected(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
