import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbDateStruct, NgbCalendar, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { ComponentService } from 'src/app/services/component.service';
import { BaseComponent } from 'src/app/components/core/base/base.component';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { PaginateData } from '../../../../../interfaces/pagination.interface';
import { JourneyRepository } from '../../../../../repositories/journey.repository';
import { Journey } from '../../../../../models/journey.model';
import { endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import {
    CalendarView,
    CalendarEvent,
} from 'angular-calendar';
import { Pattern } from '../../../../../models/pattern.model';
import { PatternJourneyRepository } from '../../../../../repositories/pattern-journey.repository';
import { CustomDatepickerI18nService } from 'src/app/services/custom-datepicker-i18n.service';

@Component({
    selector: 'app-journey-calendar-add',
    templateUrl: './journey-calendar-add.component.html',
    styleUrls: ['./journey-calendar-add.component.css'],
    providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }]
})
export class JourneyCalendarAddComponent extends BaseComponent implements OnInit {
    @ViewChild('f', { read: true }) userProfileForm: NgForm;
    formjourney: FormGroup;
    viewDate: Date = new Date();
    journeys: Journey[];
    data$: Observable<PaginateData>;
    patternGroup: Pattern[];
    journeyCalendar$: Observable<CalendarEvent[]>;
    validDeparture = true;
    validArrival = false;
    isDisabled_departure = true;
    isDisabled_arrival = true;
    @Input() formLoading;
    @Output() listJourneyCalendar = new EventEmitter();
    submitted = false;
    datesSelected: NgbDateStruct[] = [];

    view: CalendarView = CalendarView.Month;

    model: NgbDateStruct;
    date: { year: number, month: number, day: number };
    minDate: any;

    constructor(
        private formBuilder: FormBuilder,
        private calendar: NgbCalendar,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public repository: JourneyRepository,
        private partentRepos: PatternJourneyRepository,
        protected service: ComponentService) {
        super(service);
    }

    ngOnInit() {
        const d = new Date();
        const day = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        this.minDate = {
            year: year,
            month: month,
            day: day
        };
        this.formjourney = this.formBuilder.group({
            datejourney: [this.minDate],
            direction_arrival: [''],
            direction_departure: [''],
            pattern_arrival: [{ value: '', disabled: this.isDisabled_arrival }],
            pattern_departure: [{ value: '', disabled: this.isDisabled_departure }]

        });

        // check onchange item form
        this.formjourney.valueChanges.subscribe(v => {
            if (v.datejourney && (v.pattern_departure || v.pattern_arrival)) {
                this.validDeparture = false;
            } else {
                this.validDeparture = true;
            }
        })
        this.model = this.calendar.getToday();
        this.loadDataPattern();
    }

    loadDataPattern(): void {
        this.partentRepos.getAllPattern().subscribe(data => {
            const pattern = new Pattern();
            pattern.fill({ id: '', name: this.service.translate.instant('filter_label.all') });
            data.unshift(pattern);
            this.patternGroup = data;
        });
    }

    selectPatern(direction_type, isChecked: boolean): void {
        if (direction_type === 1 && isChecked) {
            this.formjourney.get('pattern_departure').enable();
        } else if (direction_type === 1 && !isChecked) {
            this.formjourney.get('pattern_departure').disable();
            this.formjourney.get('pattern_departure').patchValue('');
        }
        if (direction_type === 2 && isChecked) {
            this.formjourney.get('pattern_arrival').enable();
        } else if (direction_type === 2 && !isChecked) {
            this.formjourney.get('pattern_arrival').disable();
            this.formjourney.get('pattern_arrival').patchValue('');
        }
    }

    change(value: NgbDateStruct[]) {
        this.datesSelected = value;
        this.formjourney.patchValue({
            'datejourney': this.datesSelected
        });
    }

    get f() {
        return this.formjourney.controls;
    }

    onAddJourney(): void {
        this.formLoading = true;
        this.submitted = true;
        if (this.formjourney.invalid || (this.validDeparture && this.validArrival) || !this.datesSelected) {
            setTimeout(() => {
                this.formLoading = false;
            }, 100);

            return;
        }
        const workDates = this.formjourney.get('datejourney').value;
        const datejourney = [];
        for (const item of workDates) {
            let month_pattern = item.month;
            let day_pattern = item.day;
            if (parseInt(item.month) <= 9) {
                month_pattern = '0' + item.month;
            }
            if (parseInt(item.day) <= 9) {
                day_pattern = '0' + item.day;
            }
            const datejourneynew = item.year + '-' + month_pattern + '-' + day_pattern;
            datejourney.push(datejourneynew);
        }

        const data = {
            'workDates': datejourney
        };
        const patternIds = [];
        if (this.formjourney.get('pattern_departure').value !== '') {
            patternIds.push(parseInt(this.formjourney.get('pattern_departure').value));
        }
        if (this.formjourney.get('pattern_arrival').value !== '') {
            patternIds.push(parseInt(this.formjourney.get('pattern_arrival').value));
        }
        data['journeyPatternId'] = patternIds;
        this.repository.createJourneyCalendar(data)
            .subscribe(_ => {
                this.modalService.dismissAll('Close modal');
                const content = this.service.translate.instant('message.pattern_added_success', { date: datejourney.join(',') });
                this.service.alertFlashService.success([content], this.optionsAlert);
                const getStart: any = {
                    month: startOfMonth,
                    week: startOfWeek,
                    day: startOfDay,
                }[this.view];
                const getEnd: any = {
                    month: endOfMonth,
                    week: endOfWeek,
                    day: endOfDay,
                }[this.view];
                const dateFormYear = format(getStart(this.viewDate), 'YYYY');
                const dateFormDay = format(getStart(this.viewDate), 'DD');
                const dateToYear = format(getEnd(this.viewDate), 'YYYY');
                let dateToDay = format(getEnd(this.viewDate), 'DD');
                let itemDate = workDates[0];
                if (parseInt(itemDate.month) === 1 || parseInt(itemDate.month) === 3 || parseInt(itemDate.month) === 5 || parseInt(itemDate.month) === 7 || parseInt(itemDate.month) === 8 || parseInt(itemDate.month) === 10 || parseInt(itemDate.month) === 12) {
                    dateToDay = '31';
                } else if (parseInt(itemDate.month) === 2) {
                    dateToDay = '28';
                } else {
                    dateToDay = '30';
                }
                const params = {
                    "DateFrom": dateFormYear + '-' + itemDate.month + '-' + dateFormDay,
                    "DateTo": dateToYear + '-' + itemDate.month + '-' + dateToDay
                }
                this.repository.getJourneyCalendar(params).subscribe(datajourney => {
                    this.listJourneyCalendar.emit({ datajourney, 'workDate': itemDate.year + '-' + itemDate.month + '-' + itemDate.day });
                });
            }, (error) => {
                this.catchError(error);
            });
    }

    catchError(error: any): void {
        this.service.alertFlashService.error(error, this.optionsAlert);
    }
}
