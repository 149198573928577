import {Observable} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {
    PaginateOption,
    PaginateData,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import {CalendarAbsent} from '../models/calendar-absent.model';
import {map} from 'rxjs/operators';
import {HttpHeaders, HttpInterceptor} from '@angular/common/http';
import {NotificationModel} from '../models/notification.model';
import {Pattern} from '../models/pattern.model';

@Injectable()
export class CalendarAbsentRepository extends Repository
    implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient.get('/calendarabsent/list', option).pipe(
            map((response: any) => {
                const absents = [];
                let res = response.records.listDates;
                if (res.dates) {
                    for (const item of res.dates) {
                        absents.push(this.parseResponse(item));
                    }
                }
                return {
                    data: absents,
                    page: res.currentPage || 0,
                    total: res.totalRecord,
                    pageSize: option.displayItem,
                };
            })
        );
    }

    getAll(year: string, month: string): Observable<CalendarAbsent[]> {
        return this.httpClient.get('/schoolholiday/list?Year=' + year + '&Month=' + month).pipe(
            map((response: any) => {
                const caabsents = [];
                let res = response.records;
                if (res.listHoliday) {
                    for (const item of res.listHoliday) {
                        caabsents.push(this.parseResponse(item));
                    }
                }
                return caabsents;
            })
        );
    }
    getInfo(holiday: string, shiftstatus: number): Observable<CalendarAbsent> {
        return this.httpClient.get('/schoolholiday/detail/' + holiday + '/' + shiftstatus).pipe(
            map((res: any) => {
                return this.parseResponse(res.records.holiday);
            })
        );
    }
    deleteShiftstatus(holiday: string, shiftstatus: number): Observable<any> {
        return this.httpClient.delete('/schoolholiday/delete/' + holiday + '/' + shiftstatus);
    }
    createCalendarAbsent(holiday: string, data: any): Observable<CalendarAbsent> {
        return this.httpClient.post('/schoolholiday/create/' + holiday, data);
    }
    updateCalendarAbsent(holiday: string, shiftstatus: number, data: any): Observable<CalendarAbsent> {
        return this.httpClient.put('/schoolholiday/update/' + holiday + '/' + shiftstatus, data);
    }

    parseResponse(data: any): CalendarAbsent {
        const caabsent = new CalendarAbsent();
        caabsent.fill(data);
        return caabsent;
    }
}
