import { Model } from './model';

export class BusRoute extends Model {
  name: string;
  subNumber: string;
  directionType: number;
  routeType: number;
  status: number;
  note: String;
  pickupPoints: [pickupPoints];
  routeGroupId: number;
  groupName: String;
}

interface pickupPoints {
  id: number;
  name: string;
  latitude: number;
  longitude: string;
  address: string;
  schoolFlag: number;
  time: string;
}
