import { Model } from './model';


export class BusDriver extends Model {
  fullname: String;
  fullnameHira?: String;
  fullnameRoma?: String;
  phoneNumber: String;
  gender: Number;
  status: Number;
  success?: boolean;
  data?: any;
  hasJourney?: boolean;
  isBussy?: boolean = false;
}
