import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-validation-error',
    templateUrl: './validation-error.component.html',
    styleUrls: ['./validation-error.component.css'],
})
export class ValidationErrorComponent implements OnInit {
    @Input() control: FormControl;
    @Input() submitted: boolean;
    @Input() field: string;

    constructor(private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    getMessage() {
        let message = '';
        if (this.control.errors && this.submitted) {
            if (this.control.errors.required) {
                if (this.field !== undefined && this.field !== '') {
                    const fieldName = this.translateService.instant(this.field);
                    message = this.translateService.instant('message.is_text_required', {
                        attribute: fieldName,
                    }
                    );
                } else {
                    message = 'message.is_required';
                }

            }
            if (this.control.errors.email) {
                message = 'message.mail_invalid';
            }
            if (this.control.errors.minlength) {
                const fieldName = this.translateService.instant(this.field);
                message = this.translateService.instant('message.minlength', {
                        attribute: fieldName,
                        min: this.control.errors.minlength.requiredLength
                    }
                );
            }
            if (this.control.errors.maxlength) {
                const fieldName = this.translateService.instant(this.field);
                message = this.translateService.instant('message.maxlength', {
                    attribute: fieldName,
                    max: this.control.errors.maxlength.requiredLength
                }
                );
            }
            if (this.control.errors.birthday) {
                message = 'Oops! That date hasn’t happened yet.';
            }
            if (this.control.errors.pattern) {
                if (this.field !== undefined && this.field !== '') {
                    const fieldName = this.translateService.instant(this.field);
                    message = this.translateService.instant('message.phoneNumber_invalid', {
                        attribute: fieldName,
                    }
                    );
                } else {
                    message = 'message.invalid';
                }
            }
            if (this.control.errors.NoPassswordMatch) {
                message = 'message.match_password';
            }
        }
        return message;
    }
}
