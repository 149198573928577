import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentService } from 'src/app/services/component.service';
import { BaseComponent } from 'src/app/components/core/base/base.component';



@Component({
  selector: 'app-journey-calendar-detail',
  templateUrl: './journey-calendar-detail.component.html',
  styleUrls: ['./journey-calendar-detail.component.css']
})
export class JourneyCalendarDetailComponent  extends BaseComponent implements OnInit {
  dataJourney: any;
  sumstudent: number;
  constructor(
    public activeModal: NgbActiveModal,
    protected service: ComponentService) {
      super(service);
  }
  
  ngOnInit() {}
}
