import {ComponentService} from './../../../services/component.service';
import {BaseComponent} from './../../core/base/base.component';
import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from 'src/app/services/auth.service';
import {CustomValidators} from '../../../utils/validators';
import {SchoolAdmin} from '../../../models/school-admin.model';
import {SchoolAdminRepository} from '../../../repositories/school-admin.repository';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

    changePasswordForm = this.fb.group({
        oldPassword: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required]]
    }, {
        validator: CustomValidators.passwordMatchValidator
    });
    focusInPassword = false;
    focusInConfirmPassword = false;
    togglePassword = false;
    toggleConfirmPassword = false;

    toggleOldPassword = false;

    constructor(
        protected service: ComponentService,
        private authService: AuthService,
        private fb: FormBuilder,
        private el: ElementRef,
        private schoolAdminRepository: SchoolAdminRepository
    ) {
        super(service);
    }

    formLoading = false;
    submitted: true;
    breadcrumb: any = {
        'mainlabel': 'page.change_password',
        'links': [
            {
                'name': 'page.home',
                'isLink': true,
                'link': '/'
            },
            {
                'name': 'page.change_password',
                'isLink': false
            }
        ]
    };

    ngOnInit() {

    }

    onSubmit(): void {

        this.formLoading = true;
        this.submitted = true;
        if (this.changePasswordForm.invalid) {
            setTimeout(() => {
                this.formLoading = false;
                for (const key of Object.keys(this.changePasswordForm.controls)) {
                    if (this.changePasswordForm.controls[key].invalid) {
                        const invalidControl = this.el.nativeElement.querySelector('[name="' + key + '"]');
                        invalidControl.focus();
                        break;
                    }
                }
            }, 100);
            return;
        } else {
            const data = new ChangePassword();
            data.currentPassword = this.changePasswordForm.get('oldPassword').value;
            data.newPassword = this.changePasswordForm.get('password').value;
            data.confirmPassword = this.changePasswordForm.get('confirmPassword').value;
            data.token = this.service.localStorage.get('access_token');

            this.schoolAdminRepository.changePassword(data).subscribe(_ => {
                setTimeout(() => {
                    this.formLoading = false;
                }, 100);
                const field_name = this.service.translate.instant('message.update_profile_success');
                this.service.alertFlashService.success([field_name], this.optionsAlert);
            }, (error) => {
                const errors = error.error.errors;
                const arrayErrors = [];
                this.formLoading = false;
                if (errors !== null && errors !== undefined) {
                    Object.keys(errors).forEach((key) => {
                        for (let i = 0; i < errors[key].length; i++) {
                            arrayErrors.push(errors[key][i].toString());
                        }
                    });
                }
                this.service.alertFlashService.error(arrayErrors, this.optionsAlert);
                if (arrayErrors.length === 0) {
                    this.service.alertFlashService.error(['message.unknow_error'], this.optionsAlert);
                }

            });
        }
    }

    focusPassword() {
        this.focusInPassword = true;
    }

    focusOutPassword() {
        this.focusInPassword = false;
    }

    focusConfirmPassword() {
        this.focusInConfirmPassword = true;
    }

    toggleOldPasswordText() {
        this.toggleOldPassword = !this.toggleOldPassword;
    }

    focusOutConfirmPassword() {
        this.focusInConfirmPassword = false;
    }


    togglePasswordText() {
        this.togglePassword = !this.togglePassword;
    }

    toggleConfirmPasswordText() {
        this.toggleConfirmPassword = !this.toggleConfirmPassword;
    }

}

export class ChangePassword {
    confirmPassword: string;
    currentPassword: string
    newPassword: string
    token: string
}
