import { Model } from './model';
import { Journey } from './journey.model';


export class Pattern extends Model {
  name: string;
  note: string;
  directionType: number;
  numberOfRoutes?: number;
  numberOfJourney?: number;
  journeys?: Journey[];
  records: any;
  busRoutes: any;
  isRefer?: boolean;
}

export interface PaginatePattern {
  currentPage?: number;
  displayItem: number;
}
