import { Model } from './model';
import {BusDevice} from './bus-device.model';


export class Bus extends Model {
  company: String;
  name: String;
  brand: String;
  totalSeat: Number;
  licensePlate: String;
  status: Number;
  busDevice: any;
  seatPositions: BusSeatPosition[];
  hasJourney: boolean;
  isBussy?: boolean = false;
  busLocations: any;
  busLocation: any;
  latitude: any;
  longitude: any;
  statusJourneyTracking: number;
}

export class BusSeat extends Model {
  name: string;
  totalSeats: number;
  seatPositions: BusSeatPosition[];
  type: number;
}

export class BusSeatPosition extends Model {
  axisY: number;
  axisX: number;
  busSeatStatus: number;
  studentId?: number;
  busSeatId?: number;
  hidden?: boolean;
  style?: string; 
  settingStyle?: string;
}
