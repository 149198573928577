import {Observable} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {PaginateData, PaginateOption, PaginationRepository} from '../interfaces/pagination.interface';
import {ManageFileapk} from '../models/manage-fileapk.model';
import {map} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import {Model} from '../models/model';

@Injectable()
export class ManageFileapkRepository extends Repository
    implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient.get('/versionsystem/list', option).pipe(
            map((response: any) => {
                const documentsData = [];
                let res = response.records.listVersionSystem;
                if (res.versionSystems) {
                    for (const item of res.versionSystems) {
                        documentsData.push(this.parseResponse(item));
                    }
                }
                return {
                    data: documentsData,
                    page: res.currentPage || 0,
                    total: res.totalRecord || 0,
                    pageSize: option.displayItem,
                };
            })
        );
    }


    getInfo(id: number): Observable<ManageFileapk> {
        return this.httpClient.get('/versionsystem/detail/' + id).pipe(
            map((res: any) => {
                return this.parseResponse(res.records.versionSystem);
            })
        );
    }

    createDocument(data: any): Observable<ManageFileapk> {
        return this.httpClient
            .post('/versionsystem/create', data, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
                })
            });
    }

    parseResponse(data: any): ManageFileapk {
        const notify = new ManageFileapk();
        notify.fill(data);
        return notify;
    }

    deleteDocument(id): Observable<ManageFileapk> {
        return this.httpClient.delete('/versionsystem/delete/' + id);
    }

    updateFileApk(data) {
        return this.httpClient.put('/versionsystem/update', data);
    }

}
