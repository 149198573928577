import {Observable} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {PaginateData, PaginateOption, PaginationRepository} from '../interfaces/pagination.interface';
import {NotificationModel} from '../models/notification.model';
import {map} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import {Model} from '../models/model';
import {Bus} from '../models/bus.model';

@Injectable()
export class NotificationRepository extends Repository
    implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient.get('/information/list', option).pipe(
            map((response: any) => {
                const notificationData = [];
                let res = response.records.informations;
                if (res.informations) {
                    for (const item of res.informations) {
                        notificationData.push(this.parseResponse(item));
                    }
                }
                return {
                    data: notificationData,
                    page: res.currentPage || 0,
                    total: res.totalRecord || 0,
                    pageSize: option.displayItem,
                };
            })
        );
    }


    getInfo(id: Number): Observable<NotificationModel> {
        return this.httpClient.get('/information/detail/' + id).pipe(
            map((res: any) => {
                return this.parseResponse(res.records.information);
            })
        );
    }

    createNotify(data: any): Observable<NotificationModel> {
        return this.httpClient
            .post('/information/create', data, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
                })
            });
    }

    updateNotify(id, data: any): Observable<NotificationModel> {
        data.id = Number(id);
        return this.httpClient
            .put('/information/update', data);
    }


    sendNotification(id: number): Observable<any> {
        return this.httpClient.post('/information/sendnotification', {id: id}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
            })
        });
    }

    parseResponse(data: any): NotificationModel {
        const notify = new NotificationModel();
        notify.fill(data);
        return notify;
    }

    deleteNotify(id): Observable<NotificationModel> {
        return this.httpClient.delete('/information/delete/' + id);
    }

}

export class NotificationCreate extends Model {
    subject: string;
    content: string;
    settingDate: string;
    pickupPointIds: any;
    isSendAll: boolean;
    journeyId: number;
    files: [];
}
