import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterJourneyPattern',
  pure:false
})
export class FilterJourneyPatternPipe implements PipeTransform {

  transform(patternGroup: any[], directionType: number): any {
    if (!patternGroup || !directionType) {
        return patternGroup;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return patternGroup.filter(item => item.directionType ==  directionType);
  }

}
