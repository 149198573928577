<div class="content-wrapper mt-50 mb-50">
    <div class="content-body">
        <section>
            <div class="col-md-12 col-12 d-flex align-items-center justify-content-center">
                <div class="col-md-4 col-10 box-shadow-2 p-0">
                    <div class="card border-grey-3 border-lighten-2 m-0">
                        <div class="card-header border-0 bg-light-blue">
                            <div class="card-title text-center">
                                <h1 class="forgot-password-page-title mt-10">{{ 'reset_password.title' | translate }}</h1>
                            </div>
                        </div>
                        <div class="card-content px-1 py-1 m-0">
                            <app-message></app-message>
                            <div class="card-body">
                                <ngb-alert *ngIf="alert.show" class="alert-icon-left" [dismissible]="true"
                                            [type]="alert.type" (close)="alert.show=false" role="alert">
                                    <span class="alert-icon"><i [class]="alert.icon"></i></span>
                                    {{ alert.content }}
                                </ngb-alert>

                                <form *ngIf="checkToken && !resetPasswordSuccess" class="form-horizontal" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                                    <div class="input-group py-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text form-control">
                                                <i class="la la-lock"></i>
                                            </span>
                                        </div>
                                        <input type="password" formControlName="password" class="form-control border-left-0 shadow-none" placeholder="{{ 'auth.password' | translate }}" required>
                                        <app-validation-error [field]="'auth.password'" [submitted]="submitted"
                                                                [control]="f.password">
                                        </app-validation-error>
                                    </div>

                                    <div class="input-group py-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text form-control">
                                                <i class="la la-lock"></i>
                                            </span>
                                        </div>
                                        <input type="password" formControlName="confirmPassword" class="form-control border-left-0 shadow-none" placeholder="{{ 'auth.confirm_password' | translate }}" required>
                                        <app-validation-error [field]="'auth.confirm_password'" [submitted]="submitted"
                                                                [control]="f.confirmPassword">
                                        </app-validation-error>
                                    </div>

                                    <div class="form-group mt-15 mb-40">
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <!-- <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i> -->
                                            <i class="feather ft-unlock"></i> {{ 'reset_password.save' | translate }}
                                        </button>
                                    </div>
                                </form>
                                <div>
                                    <a class="btn btn-outline-info btn-block" href="/login">{{ 'auth.btn_login' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
