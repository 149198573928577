import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {PaginateOption} from '../interfaces/pagination.interface';
import {SchoolAdmin} from '../models/school-admin.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Student} from '../models/student.model';

@Injectable()
export class SchoolAdminRepository extends Repository {
    login(authInfo: any) {
        return this.httpClient.post('/schooladmin/login', authInfo);
    }

    googleLogin(authInfo: any) {
        return this.httpClient.post('/schooladmin/google/sign-in', authInfo);
    }

    paginate(option: PaginateOption): any {
        return this.httpClient.get('/schooladmin/list', option).pipe(
            map((response: any) => {
                const schoolAdmins = [];
                const res = response.records.listSchoolAdmin;
                if (res.schoolAdmins) {
                    for (const item of res.schoolAdmins) {
                        schoolAdmins.push(this.parseResponse(item));
                    }
                }
                return {
                    data: schoolAdmins,
                    page: res.currentPage || 0,
                    total: res.totalRecord,
                    pageSize: option.displayItem,
                };
            })
        );
    }

    getInfo(id: Number): Observable<SchoolAdmin> {
        return this.httpClient.get('/schooladmin/detail/' + id).pipe(
            map((res: any) => {
                return this.parseResponse(res.records.schooladmin);
            })
        );
    }

    me() {
        return this.httpClient.get('/schooladmin/detail', {});
    }

    updateSchoolAdmin(params: any) {
        return this.httpClient.put('/schooladmin/update', params);
    }

    updateProfile(params: any) {
        return this.httpClient.put('/schooladmin/update', params);
    }

    changePassword(params: any) {
        return this.httpClient.post('/schooladmin/settings/changepassword', params);
    }

    createSchoolAdmin(data) {
        data.schoolId = 1;
        return this.httpClient.post('/schooladmin/create', data);
    }

    deleteSchoolAdmin(id: number): Observable<any> {
        return this.httpClient.delete('/schooladmin/delete/' + id);
    }

    parseResponse(data: any): SchoolAdmin {

        let schoolAdmin: SchoolAdmin;
        schoolAdmin = new SchoolAdmin();
        schoolAdmin.fill(data);
        return schoolAdmin;
    }
}
