import {Observable} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {PaginateData, PaginateOption, PaginationRepository} from '../interfaces/pagination.interface';
import {ManageDocument} from '../models/manage-document.model';
import {map} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import {Model} from '../models/model';

@Injectable()
export class ManageDocumentRepository extends Repository
    implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient.get('/file/list', option).pipe(
            map((response: any) => {
                const documentsData = [];
                let res = response.records.listFile;
                if (res.files) {
                    for (const item of res.files) {
                        documentsData.push(this.parseResponse(item));
                    }
                }
                return {
                    data: documentsData,
                    page: res.currentPage || 0,
                    total: res.totalRecord || 0,
                    pageSize: option.displayItem,
                };
            })
        );
    }


    getInfo(id: number): Observable<ManageDocument> {
        return this.httpClient.get('/file/detail/' + id).pipe(
            map((res: any) => {
                return this.parseResponse(res.records.documentfile);
            })
        );
    }

    createDocument(data: any): Observable<ManageDocument> {
        return this.httpClient
            .post('/file/create', data, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
                })
            });
    }

    parseResponse(data: any): ManageDocument {
        const notify = new ManageDocument();
        notify.fill(data);
        return notify;
    }

    deleteDocument(id): Observable<ManageDocument> {
        return this.httpClient.delete('/file/delete/' + id);
    }

    updateStatus(data) {
        return this.httpClient.put('/file/update', data);
    }

}
