// import { DialogService } from './dialog.service';
import { MessageService } from 'src/app/services/message.service';
import { LocalStorageService } from './storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
// import { TranslocoService } from '@ngneat/transloco';
import { Location } from '@angular/common';
import { DialogService } from './dialog.service';
import { TranslateService } from '@ngx-translate/core';
import {AlertFlashService} from './alert-flash.service';

@Injectable({
  providedIn: 'root',
})
export class ComponentService {
  constructor(
      public router: Router,
      public localStorage: LocalStorageService,
      public loader: LoaderService,
      public message: MessageService,
      public dialog: DialogService,
      public location: Location,
      public translate: TranslateService,
      public alertFlashService: AlertFlashService
  ) {}
}
