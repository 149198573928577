import { PatternJourneyModule } from './components/pages/pattern-journey/pattern-journey.module';
import { RouteGroupModule } from './components/pages/route-group/route-group.module';
import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './components/layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './components/layout/private-layout/private-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/auth/login';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { Error404Component } from './components/pages/404/error404.component';
import { ChangePasswordComponent} from './components/auth/change-password/change-password.component';
import {ProfileComponent} from './components/auth/profile/profile.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent },
      { path: '404', component: Error404Component },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent }
    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'error', loadChildren: () => import('../app/components/pages/full-pages/error/error.module').then(m => m.ErrorModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'authentication', loadChildren: () => import('../app/components/pages/full-pages/authentication/authentication.module').then(m =>
          m.AuthenticationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'others', loadChildren: () => import('../app/components/pages/full-pages/others/others.module').then(m => m.OthersModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      {
        path: 'form-elements', loadChildren: () => import('../app/components/pages/forms/form-elements/form-elements.module').then(m =>
          m.FormElementsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'parent', loadChildren: () => import('../app/components/pages/parent/parent.module').then(m =>
          m.ParentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'student', loadChildren: () => import('../app/components/pages/student/student.module').then(m =>
          m.StudentModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'import', loadChildren: () => import('../app/components/pages/import/student/import-student.module').then(m =>
          m.ImportStudentModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'bus-stop', loadChildren: () => import('../app/components/pages/bus-stop/bus-stop.module').then(m =>
          m.BusStopModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bus-route',
        loadChildren: () => import('../app/components/pages/bus-route/bus-route.module').then(m =>
          m.BusRouteModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bus-assistant',
        loadChildren: () => import('../app/components/pages/bus-assistant/bus-assistant.module').then(m => m.BusAssistantModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bus', loadChildren: () => import('../app/components/pages/bus/bus.module').then(m =>
          m.BusModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bus-device', loadChildren: () => import('../app/components/pages/bus-device/bus-device.module').then(m =>
            m.BusDeviceModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bus-driver', loadChildren: () => import('../app/components/pages/bus-driver/bus-driver.module').then(m =>
          m.BusDriverModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'journey', loadChildren: () => import('../app/components/pages/journey/journey.module').then(m =>
          m.JourneyModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'journey-log', loadChildren: () => import('../app/components/pages/journey-log/journey-log.module').then(m =>
            m.JourneyLogModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'calendar-absent', loadChildren: () => import('../app/components/pages/calendar-absent/calendar-absent.module').then(m =>
            m.CalendarAbsentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'notification', loadChildren: () => import('../app/components/pages/notification/notification.module').then(m =>
          m.NotificationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'absent', loadChildren: () => import('../app/components/pages/absent/absent.module').then(m =>
            m.AbsentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'route_group', loadChildren: () => import('../app/components/pages/route-group/route-group.module').then(m =>
            m.RouteGroupModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'pattern_journey', loadChildren: () => import('../app/components/pages/pattern-journey/pattern-journey.module').then(m =>
            m.PatternJourneyModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-document', loadChildren: () => import('../app/components/pages/manage-document/manage-document.module').then(m =>
            m.ManageDocumentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-fileapk', loadChildren: () => import('../app/components/pages/manage-fileapk/manage-fileapk.module').then(m =>
            m.ManageFileapkModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'systemlogs', loadChildren: () => import('../app/components/pages/systemlogs/systemlogs.module').then(m =>
            m.SystemlogsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'school-admin', loadChildren: () => import('../app/components/pages/school-admin/school-admin.module').then(m =>
            m.SchoolAdminModule),
        canActivate: [AuthGuard]
      },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '/404' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });
