import { Model } from './model';


export class BusDevice extends Model {
  deviceName: string;
  deviceToken: string;
  memo: string;
  status: number;
  isBussy = false;
}
