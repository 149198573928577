<div class="text-left modal-parent-selection">
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">{{ 'journey.journey_add_to_calendar' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formjourney" (ngSubmit)="onAddJourney()">
            <div class="row mx-auto mb-1 justify-content-center">
                <div class="col-md-3">
                    <div class="pull-left">
                        <b>{{'page.journey_calendar' | translate }}:</b>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="input-group">
<!--                            <ngb-datepicker #dp [minDate]="minDate" formControlName="datejourney" firstDayOfWeek="7" (navigate)="date = $event.next"></ngb-datepicker>-->
                            <ngb-multi-date-picker [datesSelected]="datesSelected" (datesSelectedChange)="change($event)"></ngb-multi-date-picker>
                            <input type="hidden" formControlName="datejourney">
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>

            <div class="row mx-auto mb-1 justify-content-center">
                <div class="col-md-3">
                    <div class="checkboxsas">
                        <label>
                            <input type="checkbox" value="1" (change)="selectPatern(1, $event.target.checked)" formControlName="direction_departure">
                            {{ 'journey.departure' | translate}}
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <select id="journey_departure" class="form-control" formControlName="pattern_departure">
                        <!-- <option value=""></option>
                        <option *ngFor="let departure of patternDeparture" [value]="departure.id">{{departure.name}}</option> -->
                        <option  value="" selected hidden></option>
                        <option *ngFor="let pattern of patternGroup | filterJourneyPattern:1" [value]="pattern.id">{{pattern.name}}</option>
                    </select>
                </div>
                <div class="col-md-3"></div>
            </div>
            <div class="row mx-auto mb-1 justify-content-center">
                <div class="col-md-3">
                    <div class="checkboxsas">
                        <label>
                            <input type="checkbox" value="2" (change)="selectPatern(2, $event.target.checked)" formControlName="direction_arrival">
                            {{ 'journey.arrival' | translate}}
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <select id="journey_arrival" class="form-control" formControlName="pattern_arrival">
                        <option  value="" selected hidden></option>
                        <option *ngFor="let pattern of patternGroup | filterJourneyPattern:2" [value]="pattern.id">{{pattern.name}}</option>
                    </select>
                </div>
                <div class="col-md-3"></div>
            </div>
            <div class="row form-group">
                <div class="col-md-12" style="text-align: center;">
                    <button type="button" class="btn grey btn-outline-secondary"
                            (click)="activeModal.close('Close click')" ngbAutofocus> {{'button.cancel' | translate }}
                    </button>
                    <button type="submit" [disabled]="validDeparture || datesSelected.length == 0"
                            class="btn btn-success mx-3"><i class="feather ft-chevron-right"></i> {{ 'button.create' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
