import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
    PaginateOption,
    PaginateData,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import { Journey, JourneyLogDetail, JourneyExport } from '../models/journey-log.model';
import { map } from 'rxjs/operators';
import { CalendarEvent } from 'angular-calendar';
import { Parent } from '../models/parent.model';

const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    }
};

@Injectable()
export class JourneyLogRepository extends Repository implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient
            .get('/journey/list', option)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourney;
                    const joruneys = [];
                    if (res.journeys) {
                        for (const item of res.journeys) {
                            joruneys.push(this.parseResponse(item));
                        }
                    }
                    return {
                        data: joruneys,
                        page: res.currentPage || 0,
                        total: res.totalRecord,
                        pageSize: option.displayItem,
                    };
                })
            );
    }

    listJourneyInfor(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient
            .get('/journey/listjourneyinfor', option)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourneyInfo;
                    const joruneys = [];
                    if (res.journeys) {
                        for (const item of res.journeys) {
                            joruneys.push(this.parseResponse(item));
                        }
                    }
                    return {
                        data: joruneys,
                        page: res.currentPage || 0,
                        total: res.totalRecord,
                        pageSize: option.displayItem,
                    };
                })
            );
    }

    getListJourney(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient
            .get('/journey/listjourneycalendar', option)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourneyCalendars;
                    const joruneys = [];
                    if (res.journeySchedules) {
                        for (const item of res.journeySchedules) {
                            joruneys.push(this.parseResponse(item));
                        }
                    }
                    return {
                        data: joruneys,
                        page: res.currentPage || 0,
                        total: res.totalRecord,
                        pageSize: option.displayItem,
                    };
                })
            );
    }

    getJourneyCalendar(data: any): Observable<CalendarEvent[]> {
        return this.httpClient
            .get('/journey/journeycalendar', data)
            .pipe(
                map((response: any) => {
                    const res = response.records.journeyCalendars;
                    return res;
                })
            );
    }

    deleteJourney(id: number): Observable<any> {
        return this.httpClient.delete('/journey/delete/' + id);
    }

    parseResponse(data: any): Journey {
        const student = new Journey();
        student.fill(data);
        return student;
    }

    getInfo(id: number, workdate: string, param_query?: string): Observable<JourneyLogDetail> {
        return this.httpClient.get('/journey/detailbydate/' + id + '/' + workdate + param_query).pipe(
            map((res: any) => {
                const detail = new JourneyLogDetail();
                detail.fill(res.records.request);
                return detail;
            })
        );
    }
    getPatternJourney(data: number): Observable<any[]> {
        return this.httpClient
            .get('/journey/getpatternjourney?JourneyPatternId=' + data)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourney.journeyCreate;
                    return res;
                })
            );
    }

    getCurrentLocation(busId: number, journeyId: number): Observable<JourneyLogDetail> {
        return this.httpClient.get('/buslocation/getcurrentlocation?BusId=' + busId + '&JourneyId=' + journeyId).pipe(
            map((res: any) => {
                const detail = new JourneyLogDetail();
                detail.fill(res.records.request);
                return detail;
            })
        );
    }

    getListJourneyDetail(data: number, workDate: string): Observable<any[]> {
        return this.httpClient
            .get('/journey/listjourneydetail?JourneyPatternId=' + data + '&workDate=' + workDate)
            .pipe(
                map((response: any) => {
                    const res = response.records.listJourneyDetails.journeys;
                    return res;
                })
            );
    }
}
