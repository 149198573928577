import { Model } from './model';
import { Student } from './student.model';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class BusStop extends Model {
  name: string;
  district: string;
  address: string;
  addressMap: string;
  previewImg: string;
  note: string;
  status: number;
  longitude: number;
  latitude: number;
  time?: NgbTimeStruct;
  schoolFlag: number;
  groupId?: number;
  groupName?: string;
  numberOfStudents: number;
  numberOfStudentsGrade1: number;
  numberOfStudentsGrade2: number;
  numberOfStudentsGrade3: number;
  numberOfStudentsGrade4: number;
  numberOfStudentsGrade5: number;
  numberOfStudentsGrade6: number;
  numberOfStudentsGrade7: number;
  numberOfStudentsGrade8: number;
  numberOfStudentsGrade9: number;
  isReferStudentActive?: boolean;
}
