import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MultiLanguageService } from './multi-language.service';

const lang: any = {
  gb: 'en',
  jp: 'ja',
  vn: 'vi',
}
const I18N_VALUES = {
  'en': {
      weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'ja': {
      weekdays: ['月', '火', '水', '木', '金', '土', '日'],
      months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  },
  'vi': {
      weekdays: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
      months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
  }
  // other languages you would support
};

@Injectable({
  providedIn: 'root'
})
export class CustomDatepickerI18nService extends NgbDatepickerI18n {
  language: string;
  constructor(
    protected mutilService: MultiLanguageService
  ) {
    super()
  }

  getWeekdayShortName(weekday: number): string {
    this.language = lang[localStorage.getItem('language_code')];
    return I18N_VALUES[this.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    this.language = lang[localStorage.getItem('language_code')];
    return I18N_VALUES[this.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
