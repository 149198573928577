import {Model} from './model';
export class ManageDocument extends Model {
    fileName: string;
    filePath: string;
    files: [];
    uploadedFiles: [];
    uploadDate: string;
    status: string;
}

