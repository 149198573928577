<div class="row">
    <div class="content-header-left col-md-6 col-12 breadcrumb-new" *ngIf="breadcrumb">
        <h3 class="content-header-title mb-0 d-inline-block">{{breadcrumb.mainlabel | translate}}</h3>
        <div class="row breadcrumbs-top d-inline-block">
            <div class="breadcrumb-wrapper col-12">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" *ngFor="let link of breadcrumb.links">
                        <a *ngIf="link.isLink" routerLink="{{link.link}}">{{link!.name | translate}}</a>
                        <span *ngIf="!link.isLink">{{link!.name | translate}}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <span class="production-inform">{{informText}}</span>
    <div class="col-12">
        <app-alert-flash></app-alert-flash>
    </div>

</div>
