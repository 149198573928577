import { ComponentService } from './../../../services/component.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fragment',
  templateUrl: './fragment.component.html',
  styleUrls: ['./fragment.component.css']
})
export class FragmentComponent implements OnInit {

  constructor(protected service: ComponentService) { }

  ngOnInit(): void {
  }

}
