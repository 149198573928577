import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { BaseComponent } from './../../../components/core/base/base.component';
import { ComponentService } from '../../../services/component.service';
import { DEFAULT_REDIRECT_LINK } from 'src/app/utils/const';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { AlertService } from 'src/app/services/alert.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    isPageLoaded = false;
    hide = true;
    togglePassword = false;
    focusInEmail = false;
    focusInPassword = false;
    alert: any = {
        show: false,
        icon: 'la la-heart',
        type: 'success',
        content: 'login.failed'
    };

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        public authService: AuthService,
        private renderer: Renderer2,
        private socialAuthService: SocialAuthService,
        protected componentService: ComponentService
    ) {
        super(componentService);
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: true
        });
        // Remember Me
        if (localStorage.getItem('remember')) {
            this.renderer.removeClass(document.body, 'bg-full-screen-image');
            localStorage.removeItem('currentLayoutStyle');
            this.router.navigate(['/dashboard/sales']);
        } else if (localStorage.getItem('currentUser')) {
            // Force logout on login if not remember me
            // this.authService.doLogout();
            this.isPageLoaded = true;
        } else {
            this.isPageLoaded = true;
        }

        this.service.loader.show();
        this.authService
            .check()
            .then((isLoggedIn) => {
                if (isLoggedIn) {
                    const redirectUrl = this.authService.redirectUrl
                        ? this.authService.redirectUrl
                        : DEFAULT_REDIRECT_LINK;
                    this.router.navigate([redirectUrl]);
                } else {
                    this.service.loader.hide();
                }
            })
            .finally(() => {
                this.service.loader.hide();
            });

        this.socialAuthService.authState.subscribe((googleUser) => {
            var that = this;
            if (googleUser != null) {
                that.socialSignin(googleUser);
            }
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    signIn() {
        this.router.navigateByUrl('/student');
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        const value = {
            email: this.f.email.value,
            password: this.f.password.value
        };
        const remember = this.loginForm.value.remember;
        delete this.loginForm.value.remember;
        this.authService.login(value).then(
            (reponse) => {
                if (this.authService.isLoggedIn) {
                    /*  this.service.message.hideMessage();
                      const redirect = this.authService.redirectUrl
                          ? this.service.router.parseUrl(this.authService.redirectUrl)
                          : '/parent';*/
                    this.router.navigateByUrl('/student');
                } else {
                    this.service.message.showMessage(this.trans('login.failed'));
                }
            },
            (error) => {
                this.submitted = false;
                this.alert.type = 'danger';
                this.alert.icon = 'la la-info';
                this.alert.show = true;
                this.alert.content = 'Login failed. Please check email or password';
            }
        );
        this.router.navigateByUrl('/student');
    }

    setUserInStorage(res) {
        if (res.user) {
            localStorage.setItem('currentUser', JSON.stringify(res.user));
        } else {
            localStorage.setItem('currentUser', JSON.stringify(res));
        }
    }

    togglePasswordText() {
        this.togglePassword = !this.togglePassword;
    }

    signinWithGoogle(): void {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    socialSignin(googleUser) {
        const request = {
            token: googleUser.idToken,
            provider : "google"
        }
        this.authService
            .googleLogin(request)
            .then((response) => {
                if (this.authService.isLoggedIn) {
                    /*  this.service.message.hideMessage();
                        const redirect = this.authService.redirectUrl
                            ? this.service.router.parseUrl(this.authService.redirectUrl)
                            : '/parent';*/
                    this.router.navigateByUrl('/student');
                } else {
                    this.service.message.showMessage(this.trans('login.failed'));
                }
            }).catch(e => {
                this.submitted = false;
                this.alert.type = 'danger';
                this.alert.icon = 'la la-info';
                this.alert.show = true;
                this.alert.content = e.error.header.message || this.componentService.translate.instant('login.failed');
            });
    }
    focusEmail(){
        this.focusInEmail = true;
    }
    focusOutEmail(){
        this.focusInEmail = false;
    }
    focusPassword(){
        this.focusInPassword = true;
    }
    focusOutPassword(){
        this.focusInPassword = false;
    }
}
