import { Observable } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
  PaginateOption,
  PaginateData,
  PaginationRepository,
} from '../interfaces/pagination.interface';
import { BusDriver } from '../models/bus-driver.model';
import { map } from 'rxjs/operators';

@Injectable()
export class BusDriverRepository extends Repository implements PaginationRepository {
  paginate(option: PaginateOption): Observable<PaginateData> {
    return this.httpClient
      .get('/driver/list', option)
      .pipe(
        map((res: any) => {
          const drivers = [];
          if (res.records.listDriver.drivers) {
            for (const item of res.records.listDriver.drivers) {
              drivers.push(this.parseResponse(item));
            }
          }
          return {
            data: drivers,
            page: res.records.listDriver.currentPage || 0,
            total: res.records.listDriver.totalRecord,
            pageSize: option.displayItem,
          };
        })
      );
  };

  createBusDriver(data: any): Observable<BusDriver> {
    return this.httpClient.post('/driver/create', data);
  };

  updateBusDriver(data: any): Observable<BusDriver> {
    return this.httpClient.put('/driver/update', data);
  };

  detailBusDriver(id: any): Observable<BusDriver> {
    return this.httpClient.get('/driver/detail/' + id).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.driver);
      })
    );
  };

  deleteBusDriver(id: any): Observable<BusDriver> {
    return this.httpClient.delete('/driver/delete/' + id);
  };

  parseResponse(data: any): BusDriver {
    const drivers = new BusDriver();
    drivers.fill(data);
    return drivers;
  }

  getAll(): Observable<BusDriver[]> {
    return this.httpClient
      .get('/driver/getall')
      .pipe(
        map((res: any) => {
          const drivers = [];
          if (res.records.allDrivers.drivers) {
            for (const item of res.records.allDrivers.drivers) {
              drivers.push(this.parseResponse(item));
            }
          }
          return drivers;
        })
      );
  }

}
