import {BaseComponent} from './../../core/base/base.component';
import {CustomValidators} from './../../../utils/validators';
import {ComponentService} from './../../../services/component.service';
import {Component, OnInit} from '@angular/core';
import {Validators, FormBuilder} from '@angular/forms';
import {AuthService} from 'src/app/services/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  alert: any = {
    show: false,
    icon: 'la la-heart',
    type: 'success',
    content: 'login.failed'
  };
  resetPasswordForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
  }, {
    validator: CustomValidators.passwordMatchValidator
  });
  token: string;
  checkToken = false;
  submitted = false;
  resetPasswordSuccess = false;

  constructor(
    protected service: ComponentService,
    private authService: AuthService,
    private fb: FormBuilder,
    private titleService: Title
  ) {
    super(service);
  }
  get f() {
    return this.resetPasswordForm.controls;
  }
  ngOnInit() {
    this.resetPasswordSuccess = false;
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.authService.checkTokenResetPassword(this.token).subscribe(
      response => {
        this.checkToken = true;
      },
      error => {
        this.alert.type = 'danger';
        this.alert.icon = 'la la-info';
        this.alert.show = true;
        this.alert.content = error?.error?.header?.message;
        console.log(error?.error?.header?.message)
      }
    );
  }

  resetPassword() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.service.message.hideMessage();

    this.authService.resetPassword({...this.resetPasswordForm.value, token: this.token, newPassword: this.resetPasswordForm.value.password}).subscribe(
      response => {
        this.resetPasswordSuccess = true;
        this.alert.type = 'success';
        this.alert.icon = 'la la-info';
        this.alert.show = true;
        this.alert.content = response?.header?.message;
      },
      error => {
        this.resetPasswordSuccess = false;
        this.alert.type = 'danger';
        this.alert.icon = 'la la-info';
        this.alert.show = true;
        this.alert.content = error?.error?.header?.message;
      }
    );
  }
}
