import { Observable, BehaviorSubject, of, from } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
  PaginateOption,
  PaginateData,
  PaginationRepository,
} from '../interfaces/pagination.interface';
import { BusStop } from '../models/bus-stop.model';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class BusStopRepository extends Repository
  implements PaginationRepository {
  paginate(option: PaginateOption): Observable<PaginateData> {
    return this.httpClient.get('/pickuppoint/list', option).pipe(
      map((response: any) => {
        const busStops = [];
        let res = response.records.listPickupPoint;
        if (res.pickupPoints) {
          for (const item of res.pickupPoints) {
            busStops.push(this.parseResponse(item));
          }
        }
        return {
          data: busStops,
          page: res.currentPage || 0,
          total: res.totalRecord,
          pageSize: option.displayItem,
        };
      })
    );
  }


  getInfo(id: Number): Observable<BusStop> {
    return this.httpClient.get('/pickuppoint/detail/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
      })
    }).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.pickupPoint);
      })
    );
  }

  createBusStop(data: any): Observable<BusStop> {
    return this.httpClient
      .post('/pickuppoint/create', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
        })
      });
  }

  updateBusStop(busstop: any): Observable<any> {
    return this.httpClient.put('/pickuppoint/update', busstop);
  }

  deleteBusStop(id: number): Observable<any> {
    return this.httpClient.delete('/pickuppoint/delete/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
      })
    });
  }

  parseResponse(data: any): BusStop {
    const busstop = new BusStop();
    busstop.fill(data);
    return busstop;
  }
}
