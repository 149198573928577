import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable()
export class MultiLanguageService {
    private beLang = new BehaviorSubject('ja');
    sharedLang = this.beLang.asObservable();

    constructor() {
    }
    nextLang(lang: string) {
        this.beLang.next(lang);
    }
}
