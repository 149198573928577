import { Observable, BehaviorSubject, of, from } from 'rxjs';
import { Repository } from './repository';
import { Injectable } from '@angular/core';
import {
  PaginateOption,
  PaginateData,
  PaginationRepository,
} from '../interfaces/pagination.interface';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BusRoute } from '../models/bus-route.model';

@Injectable()
export class BusRouteRepository extends Repository
  implements PaginationRepository {
  paginate(option: PaginateOption): Observable<PaginateData> {
    return this.httpClient.get('/route/list', option).pipe(
      map((response: any) => {
        const busRoutes = [];
        let res = response.records.listRoute;
        if (res.busRoutes) {
          for (const item of res.busRoutes) {
            busRoutes.push(this.parseResponse(item));
          }
        }
        return {
          data: busRoutes,
          page: res.currentPage || 0,
          total: res.totalRecord,
          pageSize: option.displayItem,
        };
      })
    );
  }


  getInfo(id: Number): Observable<BusRoute> {
    return this.httpClient.get('/route/detail/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
      })
    }).pipe(
      map((res: any) => {
        return this.parseResponse(res.records.route);
      })
    );
  }

  createBusRoute(data: any): Observable<BusRoute> {
    return this.httpClient
      .post('/route/create', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
        })
      })
      .pipe(
        map((res: any) => {
          return this.parseResponse(res.records.route);
        }),
      );
  }

  updateBusRoute(busroute: any): Observable<any> {
    return this.httpClient.put('/route/update', busroute);
  }

  deleteBusRoute(id: number): Observable<any> {
    return this.httpClient.delete('/route/delete/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('app_access_token')
      })
    });
  }

  parseResponse(data: any): BusRoute {
    const busstop = new BusRoute();
    busstop.fill(data);
    return busstop;
  }
  getAll(): Observable<BusRoute[]> {
    return this.httpClient.get('/route/getall').pipe(
      map((response: any) => {
        const busRoutes = [];
        let res = response.records.routes;
        if (res.busRoutes) {
          for (const item of res.busRoutes) {
            busRoutes.push(this.parseResponse(item));
          }
        }
        return busRoutes;
      })
    );
  }
}
