  <div class="app-content content">
    <div class="content-wrapper">
      <div class="content-header row mb-1">
      </div>
      <div class="content-body">
        <section class="flexbox-container">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-4 col-md-8 col-10 p-0">
              <div class="card-header bg-transparent border-0">
                <h2 class="error-code text-center mb-2">404</h2>
                <h3 class="text-uppercase text-center">{{ 'page.404' | translate }} !</h3>
              </div>
              <div class="card-content">
                <div class="row py-2">
                  <div class="col-12 col-sm-12 col-md-12 mb-1">
                    <a [routerLink]="['/parent']" class="btn btn-primary btn-block"><i class="feather ft-home"></i>
                      {{ 'page.home' | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
  </div>
