<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
     [ngClass]="selectedHeaderNavBarClass">
    <div class="navbar-wrapper">
        <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
             (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
            <ul class="nav navbar-nav flex-row">
                <!-- Remove position relative in responsive -->
                <li class="nav-item mobile-menu d-lg-none mr-auto"><a
                        class="nav-link nav-menu-main menu-toggle hidden-xs11"
                        (click)="toggleNavigation($event)">
                    <i class="feather ft-menu font-large-1"></i></a></li>
                <li class="nav-item mr-auto"><a [routerLink]="['/']" class="navbar-brand"
                                                routerLink="/"><img class="brand-logo"
                                                                                   alt="modern admin logo"
                                                                                   [src]="_themeSettingsConfig.brand.logo.value">
                    <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
                </a></li>
                <li class="nav-item d-none d-md-block nav-toggle">
                    <a [routerLink]="" class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
                       (click)="toggleFixMenu($event)">
                        <i class="feather toggle-icon font-medium-3 white"
                           [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu == 'collapse','ft-toggle-right': _themeSettingsConfig.menu == 'expand'}"></i>
                    </a>
                </li>
                <li class="nav-item d-lg-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
                                                  data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i
                        class="la la-ellipsis-v"></i></a></li>
            </ul>
        </div>
        <!-- New-->
        <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
            <div class="collapse navbar-collapse" id="navbar-mobile">
                <ul class="nav navbar-nav mr-auto float-left">

                </ul>
                <ul class="nav navbar-nav float-right">

                    <app-multi-language internationalization="{{internationalization}}"></app-multi-language>

                    <li class="dropdown-user nav-item" ngbDropdown>
                        <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="currentUser.fullName"
                    class="mr-1 user-name text-bold-700">{{currentUser.fullName}}</span>
                            <span *ngIf="!currentUser.fullName"
                                  class="mr-1 user-name text-bold-700">{{currentUser.fullName}}</span>
                            <span class="avatar avatar-online">
                <img *ngIf="currentUser.photoURL" src="{{currentUser.photoURL}}" alt="avatar">
                <img *ngIf="!currentUser.photoURL" src="../../../assets/images/portrait/small/admin-default-avatar.png"
                     alt="avatar">
                <i></i>
              </span>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                             aria-labelledby="dropdownProfileMenu">
                            <a class="dropdown-item" [routerLink]="['/profile']" ><i class="feather ft-info"></i>
                                {{ 'page.edit_profile' | translate }}</a>
                            <a class="dropdown-item" [routerLink]="['/change-password']" ><i class="la la-key"></i>
                                {{ 'page.change_password' | translate }}</a>
                            <a class="dropdown-item" [routerLink]="" (click)="logout()"><i class="feather ft-power"></i>
                                {{ 'auth.btn_signout' | translate }}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- New-->
    </div>
</nav>
