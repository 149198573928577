import {Observable} from 'rxjs';
import {Repository} from './repository';
import {Injectable} from '@angular/core';
import {
    PaginateOption,
    PaginateData,
    PaginationRepository,
} from '../interfaces/pagination.interface';
import {Absent} from '../models/absent.model';
import {map} from 'rxjs/operators';
import {HttpHeaders, HttpInterceptor} from '@angular/common/http';

@Injectable()
export class AbsentRepository extends Repository
    implements PaginationRepository {
    paginate(option: PaginateOption): Observable<PaginateData> {
        return this.httpClient.get('/request/absentlist', option).pipe(
            map((response: any) => {
                const absents = [];
                let res = response.records.listAbsentRequests;
                if (res.requests) {
                    for (const item of res.requests) {
                        absents.push(this.parseResponse(item));
                    }
                }
                return {
                    data: absents,
                    page: res.currentPage || 0,
                    total: res.totalRecord,
                    pageSize: option.displayItem,
                };
            })
        );
    }

    getInfo(id: string): Observable<Absent> {
        return this.httpClient.get('/request/absent/detail/' + id, {}).pipe(
            map((res: any) => {
                return this.parseResponse(res.records.request);
            })
        );
    }

    cancelAbsent(data: any): Observable<Absent> {
        return this.httpClient.put('/request/update', data);
    }

    createAbsent(data: any): Observable<Absent> {
        return this.httpClient.post('/request/createRequestMany', data);
    }

    deleteManyAbsent(data: any): Observable<Absent> {
        return this.httpClient.post('/request/deleterequestselected', data);
    }

    parseResponse(data: any): Absent {
        const absent = new Absent();
        absent.fill(data);
        return absent;
    }
}
