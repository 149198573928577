import {Model} from './model';
interface pickupPoints {
    id: number;
    name: string;
    latitude: number;
    longitude: string;
    address: string;
    schoolFlag: number;
    time: string;
}
export class NotificationModel extends Model {
    subject: string;
    content: string;
    settingDate: string;
    files: [];
    uploadedFiles: [];
    isSendAll: boolean;
    status: string;
    isSend: boolean;
    journeyId: number;
    pickUpPoints: [pickupPoints];
    isSendAllStudent: boolean;
    grades: string;
}

