import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'src/app/components/pages/partials/general/card/card.module';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {AlertFlashModule} from '../../../_alert/alert-flash.module';


@NgModule({
    imports: [
        CommonModule,
        CardModule,
        NgbModule,
        RouterModule.forChild([]),
        TranslateModule,
        AlertFlashModule
    ],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbModule { }
