<li class="dropdown-language nav-item" ngbDropdown *ngIf="dropdownStatus === 'on'">
    <a [routerLink]="" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
        <i class="flag-icon flag-icon-{{langSelect}}"></i><span class="selected-language"></span>
    </a>
    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">

        <a *ngFor="let lang of listLanguage" (click)="useLanguage(lang.key)" class="dropdown-item">
            <i class="flag-icon flag-icon-{{lang.key}}"></i> {{lang.text | translate}}
        </a>
    </div>
</li>
