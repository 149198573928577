import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { JourneyRepository } from 'src/app/repositories/journey.repository';

@Component({
  selector: 'app-popup-refresh',
  templateUrl: './popup-refresh.component.html',
  styleUrls: ['./popup-refresh.component.css']
})
export class PopupRefreshComponent implements OnInit {

  @Output() status = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onRefreshJourney() {
    this.status.emit(1);
  }

  closePopup() {
    this.status.emit(0);
  }

}
